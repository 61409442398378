const hosts = {
  localhost: 'http://localhost',
  stage: 'https://apidev.actionsportscommunity.com',
  prod: 'https://api.actionsportscommunity.com',
};

const routes = {
  auth: {
    apiext: 'v1/identities',
    port: 3100,
  },
  user: {
    apiext: 'v1/profiles',
    port: 3000,
  },
  comms: {
    apiext: 'v1/email',
    port: 3900,
  },
  tags: {
    apiext: 'v1/tags',
    port: 3033,
  },
  messages: {
    apiext: 'v1/messages',
    port: 3034,
  },
};

const createUrl = (host, port, apiext) => {
  return `${host}${port ? `:${port}/` : '/'}${apiext}`;
};

const createRoutes = (api) => Object.keys(routes).reduce((obj, key) => {
  const host = hosts[api];
  const port = api !== 'localhost' ? false : routes[key].port;
  const url = createUrl(host, port, routes[key].apiext);
  return { ...obj, [key]: url };
}, {});

export default createRoutes;
