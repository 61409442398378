import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'typeface-roboto/index.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { goBack } from 'connected-react-router';
import { createStructuredSelector } from 'reselect';
import { sideNav, alertModal } from '../../redux/app/actions';
import { signOut, validateToken } from '../../redux/session/actions';
import {
  makeSelectPathName,
  makeSelectGreaterThan,
  makeSelectLessThan,
  makeSelectSideNavIsOpen,
  makeSelectAlertModalIsOpen,
  makeSelectAlertModalData,
} from '../../redux/app/selectors';
import { makeSelectIsAuthenticated } from '../../redux/session/selectors';
import { makeSelectParticipant } from '../../redux/conversation/selectors';
import AppComponent from './components/AppComponent';
import '../../styles/global.css';
import { safePush } from '../../utils';

class App extends Component {
  static propTypes = {
    push: PropTypes.func.isRequired,
    closeSideNav: PropTypes.func.isRequired,
    signOut: PropTypes.func.isRequired,
    validateToken: PropTypes.func.isRequired,
    location: PropTypes.shape({ pathname: PropTypes.string }).isRequired,
  }

  componentDidMount() {
    if (this.props.location.pathname !== '/signup/2') {
      this.props.validateToken();
    }
  }

  pushFromSideNav = (path) => {
    this.props.push(path);
    this.props.closeSideNav();
  }

  pushToSignUpPage = () => this.props.push('/signup');

  pushToSignInPage = () => this.props.push('/signin');

  pushFromSideNavToSignUpPage = () => this.pushFromSideNav('/signup');

  pushFromSideNavToSignInPage = () => this.pushFromSideNav('/signin');

  signOutFromSideNav = () => {
    this.props.signOut();
    this.props.closeSideNav();
  }

  pushFromSideNavToHomePage = () => this.pushFromSideNav('/');

  pushFromSideNavToAboutPage = () => this.pushFromSideNav('/about');

  pushFromSideNavToStorePage = () => this.pushFromSideNav('/store');

  pushFromSideNavToContactPage = () => this.pushFromSideNav('/contact');

  pushFromSideNavToHelpPage = () => this.pushFromSideNav('/help');

  pushFromSideNavToNearMePage = () => this.pushFromSideNav('/near-me');

  pushFromSideNavToEventsPage = () => this.pushFromSideNav('/events');

  pushFromSideNavToGroupsPage = () => this.pushFromSideNav('/groups');

  pushFromSideNavToInboxPage = () => this.pushFromSideNav('/inbox');

  pushFromSideNavToProfilePage = () => this.pushFromSideNav('/profile');

  pushFromSideNavToAlertsPage = () => this.pushFromSideNav('/alerts');

  render() {
    return (
      <AppComponent
        pushToSignUpPage={this.pushToSignUpPage}
        pushToSignInPage={this.pushToSignInPage}
        pushFromSideNavToSignUpPage={this.pushFromSideNavToSignUpPage}
        pushFromSideNavToSignInPage={this.pushFromSideNavToSignInPage}
        signOutFromSideNav={this.signOutFromSideNav}
        pushFromSideNavToHomePage={this.pushFromSideNavToHomePage}
        pushFromSideNavToAboutPage={this.pushFromSideNavToAboutPage}
        pushFromSideNavToStorePage={this.pushFromSideNavToStorePage}
        pushFromSideNavToContactPage={this.pushFromSideNavToContactPage}
        pushFromSideNavToHelpPage={this.pushFromSideNavToHelpPage}
        pushFromSideNavToNearMePage={this.pushFromSideNavToNearMePage}
        pushFromSideNavToEventsPage={this.pushFromSideNavToEventsPage}
        pushFromSideNavToGroupsPage={this.pushFromSideNavToGroupsPage}
        pushFromSideNavToInboxPage={this.pushFromSideNavToInboxPage}
        pushFromSideNavToProfilePage={this.pushFromSideNavToProfilePage}
        pushFromSideNavToAlertsPage={this.pushFromSideNavToAlertsPage}
        {...this.props}
      />
    );
  }
}

const mapStateToProps = createStructuredSelector({
  path: makeSelectPathName(),
  greaterThan: makeSelectGreaterThan(),
  lessThan: makeSelectLessThan(),
  sideNavIsOpen: makeSelectSideNavIsOpen(),
  alertModalIsOpen: makeSelectAlertModalIsOpen(),
  alertModalData: makeSelectAlertModalData(),
  isAuthenticated: makeSelectIsAuthenticated(),
  conversationUser: makeSelectParticipant(),
});

const mapDispatchToProps = (dispatch) => ({
  openSideNav: () => dispatch(sideNav.open()),
  closeSideNav: () => dispatch(sideNav.close()),
  closeAlertModal: () => dispatch(alertModal.close()),
  push: (page) => safePush(page, dispatch),
  goBack: () => dispatch(goBack()),
  signOut: () => {
    safePush('/', dispatch);
    dispatch(signOut());
  },
  validateToken: () => dispatch(validateToken.request()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
