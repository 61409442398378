import React from 'react';
import PropTypes from 'prop-types';
import Loadable from 'react-loadable';

const minHeight = ({ bottomNav, greaterThan, lessThan }) => {
  let padding = 65;
  if (greaterThan.medium) padding = 90;
  if (bottomNav && lessThan.medium) padding = 121;
  return `calc(100vh - ${padding}px)`;
};

const paddingBottom = ({ bottomNav, lessThan, isAuthenticated }) => {
  return lessThan.medium && bottomNav && isAuthenticated && 56;
};

const LoadableFooter = Loadable({
  loader: () => import('../../../../components/Footer'),
  loading: () => null,
  delay: 200,
});

const LoadableBottomNav = Loadable({
  loader: () => import('../../../../components/BottomNav'),
  loading: () => null,
  delay: 200,
});

const AsyncRouteComponent = ({
  component: Component,
  isAuthenticated,
  scrollToTop,
  ...props
}) => (
  <div
    className="page"
    style={{
      minHeight: minHeight(props),
      paddingBottom: paddingBottom(props),
    }}
  >
    <section className="page__content">
      <Component {...props} />
    </section>
    {props.footer && !isAuthenticated
      && (
        <LoadableFooter
          isAuthenticated={isAuthenticated}
          scrollToTop={scrollToTop}
        />
      )}
    {props.lessThan.medium && props.bottomNav && isAuthenticated
      && <LoadableBottomNav {...props} />}
  </div>
);

AsyncRouteComponent.propTypes = {
  component: PropTypes.func.isRequired,
  greaterThan: PropTypes.shape({
    extraSmall: PropTypes.bool.isRequired,
    small: PropTypes.bool.isRequired,
    medium: PropTypes.bool.isRequired,
    large: PropTypes.bool.isRequired,
    infinity: PropTypes.bool.isRequired,
  }).isRequired,
  lessThan: PropTypes.shape({
    extraSmall: PropTypes.bool.isRequired,
    small: PropTypes.bool.isRequired,
    medium: PropTypes.bool.isRequired,
    large: PropTypes.bool.isRequired,
    infinity: PropTypes.bool.isRequired,
  }).isRequired,
  footer: PropTypes.bool,
  bottomNav: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  scrollToTop: PropTypes.func.isRequired,
};

export default AsyncRouteComponent;
