export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';

export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';

export const SAVE_USER_REQUEST = 'SAVE_USER_REQUEST';
export const SAVE_USER_SUCCESS = 'SAVE_USER_SUCCESS';
export const SAVE_USER_FAILURE = 'SAVE_USER_FAILURE';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

export const UPDATE_USER_PASSWORD_REQUEST = 'UPDATE_USER_PASSWORD_REQUEST';
export const UPDATE_USER_PASSWORD_SUCCESS = 'UPDATE_USER_PASSWORD_SUCCESS';
export const UPDATE_USER_PASSWORD_FAILURE = 'UPDATE_USER_PASSWORD_FAILURE';

export const GET_NEAR_USERS_REQUEST = 'GET_NEAR_USERS_REQUEST';
export const GET_NEAR_USERS_SUCCESS = 'GET_NEAR_USERS_SUCCESS';
export const GET_NEAR_USERS_FAILURE = 'GET_NEAR_USERS_FAILURE';

export const LINK_TO_FACEBOOK_REQUEST = 'LINK_TO_FACEBOOK_REQUEST';
export const LINK_TO_FACEBOOK_SUCCESS = 'LINK_TO_FACEBOOK_SUCCESS';
export const LINK_TO_FACEBOOK_FAILURE = 'LINK_TO_FACEBOOK_FAILURE';
