import { combineReducers } from 'redux-immutable';
import { connectRouter } from 'connected-react-router/immutable';
import { reducer as reduxForm } from 'redux-form/immutable';
import { responsiveStateReducer } from 'redux-responsive';
import appReducer from '../redux/app/reducer';
import sessionReducer from '../redux/session/reducer';
import usersReducer from '../redux/users/reducer';
import mapReducer from '../redux/map/reducer';
import tagsReducer from '../redux/tags/reducer';
import entitiesReducer from '../redux/entities/reducer';
import conversationReducer from '../redux/conversation/reducer';

const rootReducer = (history) => combineReducers({
  router: connectRouter(history),
  form: reduxForm,
  browser: responsiveStateReducer,
  app: appReducer,
  session: sessionReducer,
  users: usersReducer,
  map: mapReducer,
  tags: tagsReducer,
  entities: entitiesReducer,
  conversation: conversationReducer,
});

export default rootReducer;
