import { fromJS } from 'immutable';
import {
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  SAVE_USER_REQUEST,
  SAVE_USER_SUCCESS,
  SAVE_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  UPDATE_USER_PASSWORD_REQUEST,
  UPDATE_USER_PASSWORD_SUCCESS,
  UPDATE_USER_PASSWORD_FAILURE,
  GET_NEAR_USERS_REQUEST,
  GET_NEAR_USERS_SUCCESS,
  GET_NEAR_USERS_FAILURE,
  LINK_TO_FACEBOOK_REQUEST,
  LINK_TO_FACEBOOK_SUCCESS,
  LINK_TO_FACEBOOK_FAILURE,
} from './constants';

/**
* Users Initial State.
*/
export const initialState = fromJS({
  loading: {
    getUsers: false,
    getUser: false,
    saveUser: false,
    updateUser: false,
    updatePassword: false,
    linkToFacebook: false,
  },
  errors: {
    getUsers: false,
    getUser: false,
    saveUser: false,
    updateUser: false,
    updatePassword: false,
    linkToFacebook: false,
  },
});

export default (state = initialState, action) => {
  switch (action.type) {
  /**
    * GET_USERS
    */
  case GET_USERS_REQUEST:
    return state
      .setIn(['loading', 'getUsers'], true)
      .setIn(['errors', 'getUsers'], false);
  case GET_USERS_SUCCESS:
    return state
      .setIn(['loading', 'getUsers'], false)
      .merge(fromJS(action.payload)
        .groupBy((user) => user.get('identityID'))
        .map((user) => user.get(0).delete('__v').delete('identityID')));
  case GET_USERS_FAILURE:
    return state
      .setIn(['loading', 'getUsers'], false)
      .setIn(['errors', 'getUsers'], action.error);
    /**
    * GET_USER
    */
  case GET_USER_REQUEST:
    return state
      .setIn(['loading', 'getUser'], true)
      .setIn(['errors', 'getUser'], false);
  case GET_USER_SUCCESS:
    return state
      .setIn(['loading', 'getUser'], false)
      .merge(fromJS(action.payload)
        .groupBy((user) => user.get('identityID'))
        .map((user) => user.get(0).delete('__v').delete('identityID')));
  case GET_USER_FAILURE:
    return state
      .setIn(['loading', 'getUser'], false)
      .setIn(['errors', 'getUser'], action.error);
    /**
    * SAVE_USER
    */
  case SAVE_USER_REQUEST:
    return state
      .setIn(['loading', 'saveUser'], true)
      .setIn(['errors', 'saveUser'], false);
  case SAVE_USER_SUCCESS: {
    const user = fromJS(action.payload);
    return state
      .setIn(['loading', 'saveUser'], false)
      .set(user.get('identityID'), user.delete('__v').delete('identityID'));
  }
  case SAVE_USER_FAILURE:
    return state
      .setIn(['loading', 'saveUser'], false)
      .setIn(['errors', 'saveUser'], action.error);
    /**
    * UPDATE_USER
    */
  case UPDATE_USER_REQUEST:
    return state
      .setIn(['loading', 'updateUser'], true)
      .setIn(['errors', 'updateUser'], false);
  case UPDATE_USER_SUCCESS: {
    const user = fromJS(action.payload);
    return state
      .setIn(['loading', 'updateUser'], false)
      .set(user.get('identityID'), user.delete('__v').delete('identityID'));
  }
  case UPDATE_USER_FAILURE:
    return state
      .setIn(['loading', 'updateUser'], false)
      .setIn(['errors', 'updateUser'], action.error);
    /**
    * UPDATE_USER
    */
  case UPDATE_USER_PASSWORD_REQUEST:
    return state
      .setIn(['loading', 'updatePassword'], false)
      .setIn(['errors', 'updatePassword'], false);
  case UPDATE_USER_PASSWORD_SUCCESS:
    return state
      .setIn(['loading', 'updatePassword'], true)
      .set('updatePassword', fromJS(action.payload));
  case UPDATE_USER_PASSWORD_FAILURE:
    return state
      .setIn(['errors', 'updatePassword'], action.error);
    /**
    * GET_NEAR_BY_USERS
    */
  case GET_NEAR_USERS_REQUEST:
    return state
      .setIn(['loading', 'getUsers'], true)
      .setIn(['errors', 'getUsers'], false);
  case GET_NEAR_USERS_SUCCESS:
    return state
      .setIn(['loading', 'getUsers'], false)
      .merge(fromJS(action.payload)
        .groupBy((user) => user.get('identityID'))
        .map((user) => user.get(0).delete('__v').delete('identityID')));
  case GET_NEAR_USERS_FAILURE:
    return state
      .setIn(['loading', 'getUsers'], false)
      .setIn(['errors', 'getUsers'], action.error);
    /**
    * LINK_TO_FACEBOOK
    */
  case LINK_TO_FACEBOOK_REQUEST:
    return state
      .setIn(['loading', 'linkToFacebook'], true)
      .setIn(['errors', 'linkToFacebook'], false);
  case LINK_TO_FACEBOOK_SUCCESS:
    return state
      .setIn(['loading', 'linkToFacebook'], false);
  case LINK_TO_FACEBOOK_FAILURE:
    return state
      .setIn(['loading', 'linkToFacebook'], false)
      .setIn(['errors', 'linkToFacebook'], action.error);
  default:
    return state;
  }
};
