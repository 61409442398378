import {
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  SAVE_USER_REQUEST,
  SAVE_USER_SUCCESS,
  SAVE_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  UPDATE_USER_PASSWORD_REQUEST,
  UPDATE_USER_PASSWORD_SUCCESS,
  UPDATE_USER_PASSWORD_FAILURE,
  GET_NEAR_USERS_REQUEST,
  GET_NEAR_USERS_SUCCESS,
  GET_NEAR_USERS_FAILURE,
  LINK_TO_FACEBOOK_REQUEST,
  LINK_TO_FACEBOOK_SUCCESS,
  LINK_TO_FACEBOOK_FAILURE,
} from './constants';

/**
* Get Users actions.
*/
export const getUsers = {
  request: () => ({ type: GET_USERS_REQUEST }),
  success: (data) => ({ type: GET_USERS_SUCCESS, payload: data }),
  failure: (error) => ({ type: GET_USERS_FAILURE, error }),
};

/**
* Get User actions.
*/
export const getUser = {
  request: (id) => ({ type: GET_USER_REQUEST, payload: id }),
  success: (data) => ({ type: GET_USER_SUCCESS, payload: data }),
  failure: (error) => ({ type: GET_USER_FAILURE, error }),
};

/**
* Save User actions.
*/
export const saveUser = {
  request: (data) => ({ type: SAVE_USER_REQUEST, payload: data }),
  success: (data) => ({ type: SAVE_USER_SUCCESS, payload: data }),
  failure: (error) => ({ type: SAVE_USER_FAILURE, error }),
};

/**
* Update User actions.
*/
export const updateUser = {
  request: (data) => ({ type: UPDATE_USER_REQUEST, payload: data }),
  success: (data) => ({ type: UPDATE_USER_SUCCESS, payload: data }),
  failure: (error) => ({ type: UPDATE_USER_FAILURE, error }),
};

/**
* Update User Password actions.
*/
export const updateUserPassword = {
  request: (data) => ({ type: UPDATE_USER_PASSWORD_REQUEST, payload: data }),
  success: (data) => ({ type: UPDATE_USER_PASSWORD_SUCCESS, payload: data }),
  failure: (error) => ({ type: UPDATE_USER_PASSWORD_FAILURE, error }),
};

/**
* Get Near By Users actions.
*/
export const getNearByUsers = {
  request: (data) => ({ type: GET_NEAR_USERS_REQUEST, payload: data }),
  success: (data) => ({ type: GET_NEAR_USERS_SUCCESS, payload: data }),
  failure: (error) => ({ type: GET_NEAR_USERS_FAILURE, error }),
};

/**
* Link to facebook actions.
*/
export const linkToFacebook = {
  request: (data) => ({ type: LINK_TO_FACEBOOK_REQUEST, payload: data }),
  success: (data) => ({ type: LINK_TO_FACEBOOK_SUCCESS, payload: data }),
  failure: (error) => ({ type: LINK_TO_FACEBOOK_FAILURE, error }),
};
