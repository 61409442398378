const config = {
  APP_PORT: process.env.APP_PORT || 8080,
  APP_HOST: process.env.APP_HOST || '0.0.0.0',
  SRV_NAME: process.env.SRV_NAME || 'reactweb',
  NODE_ENV: process.env.NODE_ENV || 'development',
  API_HOST: process.env.API_HOST || 'localhost',  // valid values: localhost, stage, prod
  CORDOVA: process.env.CORDOVA,
  FACEBOOK_APP_ID: process.env.FACEBOOK_APP_ID || '1734329166582790',
  GOOGLEMAPS_SECRET: process.env.GOOGLEMAPS_SECRET
    || 'AIzaSyAQdGkE-Ah9Th_Sr9GTDDE9T5sKuJokTCA',
  RECAPTCHA_SITE_KEY: process.env.RECAPTCHA_SITE_KEY
    || '6Ldenj8UAAAAAP4hDcDtErsDzyTn_0Zd6Fcw-0TR',
  HOCKEY_APPID: process.env.HOCKEY_APPID || 'b46f703dfc4048fa80833b9c29445cac',
  BIRTHDAY_START_YR_LIMIT: 100,
  BIRTHDAY_END_YR_LIMIT: 7,
};

module.exports = config;
