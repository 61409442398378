import {
  call, put, select, takeEvery,
} from 'redux-saga/effects';
import decode from 'jwt-decode';
import { callApi } from '../../api/api';
import { retry } from '../../utils/sagaHelpers';
import { getAuthToken, setAuthToken, removeAuthToken } from '../../utils/localStorageStore';
import {
  SIGN_UP_REQUEST,
  CONFIRM_SIGN_UP_REQUEST,
  SIGN_IN_REQUEST,
  SIGN_OUT,
  FORGOT_PASSWORD_REQUEST,
  RESET_PASSWORD_REQUEST,
  VALIDATE_TOKEN_REQUEST,
  FACEBOOK_SIGN_UP_REQUEST,
  FACEBOOK_SIGN_IN_REQUEST,
  HELP_FORM_REQUEST,
  CONTACT_FORM_REQUEST,
} from './constants';
import {
  signUp,
  signIn,
  confirmSignUp,
  forgotPassword,
  resetPassword,
  validateToken,
  facebookSignUp,
  facebookSignIn,
  helpForm,
  contactForm,
} from './actions';
import { saveUser } from '../users/actions';
import { makeSelectIsAuthenticated, makeSelectSessionUserData } from './selectors';
import { selectReferalTag } from '../tags/selectors';

/**
* Retry for a session requests.
*/
export const retrySession = retry({ delay: 200, attempt: 1 });

/**
* Get Token saga.
*/
export function* getToken({ body, type }) {
  const params = {
    method: 'POST',
    api: 'AUTH',
    endpoint: `/login/${type || 'basic'}`,
    body,
  };

  const data = yield call(retrySession, callApi, params);

  yield call(setAuthToken, data);
}

/**
* Sign Up Flow.
*/
export function* signUpFlow(action) {
  const { data, resolve, reject } = action.payload;
  try {
    const email = data.get('email');
    const password = data.get('password');

    const body = { email, password };

    const params = { method: 'POST', api: 'AUTH', body };

    const result = yield call(retrySession, callApi, params);

    const { _id } = result;
    const resp = { email, _id };
    yield put(signUp.success(resp));
    resolve(resp);
  } catch (error) {
    const message = error.message || 'There is a problem signing you up please try again later';
    yield put(signUp.failure(message));
    reject(message);
  }
}

/**
* Confirm Sign Up Flow.
*/
export function* confirmSignUpFlow(action) {
  try {
    const token = action.payload;

    const params = { api: 'AUTH', endpoint: `/confirm?token=${token}` };

    yield call(retrySession, callApi, params);

    yield put(confirmSignUp.success());
  } catch (error) {
    yield put(confirmSignUp.failure(error.message || 'There is a problem confirming your account please try again later'));
  }
}

/**
* Sign In via Email Flow.
*/
export function* signInFlow(action) {
  const { data, resolve, reject } = action.payload;
  try {
    const body = {
      email: data.get('email'),
      password: data.get('password'),
    };

    yield call(getToken, { body });

    const token = yield call(getAuthToken);

    const decodedToken = yield call(decode, token);

    const {
      exp, iat, identity: {
        _id, email, roles, status,
      },
    } = decodedToken;

    if (status === 'PENDING') {
      const errorMessage = 'You need to confirm your email address';
      reject(errorMessage);
      throw new Error(errorMessage);
    }

    const successData = {
      _id, email, roles, status, exp, iat,
    };
    yield put(signIn.success(successData));
    resolve(successData);
  } catch (error) {
    const message = error.message || 'There is a problem signing you in please try again later';
    yield put(signIn.failure(message));
    reject(message);
  }
}

/**
* Sign Out Flow.
*/
export function* signOutFlow() {
  yield call(removeAuthToken);
}

/**
* Forgot Password Flow.
*/
export function* forgotPasswordFlow(action) {
  const { data, resolve, reject } = action.payload;
  try {
    const params = {
      method: 'POST',
      api: 'AUTH',
      endpoint: '/password/forgotten',
      body: data,
    };

    yield call(retrySession, callApi, params);

    yield put(forgotPassword.success());
    resolve();
  } catch (error) {
    const message = error.message || 'There is a problem with server right now please try again later';
    yield put(forgotPassword.failure(message));
    reject(message);
  }
}

/**
* Reset Password Flow.
*/
export function* resetPasswordFlow(action) {
  const { data, resolve, reject } = action.payload;
  try {
    const params = {
      method: 'POST',
      api: 'AUTH',
      endpoint: '/password/reset',
      body: data.delete('confirmNewPassword'),
    };

    yield call(retrySession, callApi, params);

    yield put(resetPassword.success());
    resolve();
  } catch (error) {
    const message = error.message || 'There is a problem with server right now please try again later';
    yield put(resetPassword.failure(message));
    reject(message);
  }
}

/**
* Help Form Submit Flow.
*/
export function* helpFormSubmitForm(action) {
  const { data, resolve, reject } = action.payload;
  const subject = data.get('subject');
  const message = data.get('message');
  const user = yield select(makeSelectSessionUserData());

  const body = {
    templateName: 'message_help',
    templateData: {
      to: 'help@actionsportscommunity.com',
      from: user.get('email'),
      subject,
      message,
    },
  };

  try {
    const params = {
      method: 'POST',
      api: 'comms',
      endpoint: '/',
      token: true,
      body,
    };

    yield call(retrySession, callApi, params);

    yield put(helpForm.success());
    resolve('Success');
  } catch (e) {
    const m = e.message || 'There is a problem with server right now please try again later';
    yield put(helpForm.failure(m));
    reject(m);
  }
}

/**
* contactForm Form Submit Flow.
*/
export function* contactFormSubmitForm(action) {
  const { data, resolve, reject } = action.payload;
  const subject = data.get('subject');
  const message = data.get('message');
  const email = data.get('email');
  const name = data.get('name');
  const captcha = data.get('captcha');

  const bodyForRecaptcha = {
    'g-recaptcha-response': captcha,
  };
  const params = {
    method: 'POST',
    api: 'AUTH',
    endpoint: '/recaptcha',
    body: bodyForRecaptcha,
  };

  try {
    const captchaResponse = yield call(retrySession, callApi, params);
    const body = {
      to: 'help@actionsportscommunity.com',
      from: email,
      subject: `${subject}`,
      message: `${message} \n message from ${name}`,
    };

    try {
      const paramsEmail = {
        method: 'POST',
        api: 'comms',
        endpoint: '/',
        token: true,
        tmpToken: captchaResponse,
        body,
      };

      yield call(retrySession, callApi, paramsEmail);

      yield put(contactForm.success());
      resolve('Success');
    } catch (e) {
      const m = e.message || 'There is a problem with server right now please try again later';
      yield put(contactForm.failure(m));
      reject(m);
    }
  } catch (e) {
    const m = e.message || 'Error Validating Recaptcha';
    yield put(contactForm.failure(m));
    reject(m);
  }
}

/**
* Validate Token Flow.
*/
export function* validateTokenFlow() {
  try {
    const token = yield call(getAuthToken);
    const isAuthenticated = yield select(makeSelectIsAuthenticated());

    const params = { api: 'AUTH', token: true };

    if (token && !isAuthenticated) {
      const [{ facebookID }] = yield call(retrySession, callApi, params);

      const decodedToken = yield call(decode, token);

      const {
        exp, iat, identity: {
          _id, email, roles, status,
        },
      } = decodedToken;

      if (status === 'PENDING') {
        throw new Error('You need to confirm your email address');
      }

      yield put(validateToken.success({
        _id, email, facebookID, roles, status, exp, iat,
      }));
    } else {
      yield call(removeAuthToken);
      yield put(validateToken.failure());
    }
  } catch (error) {
    yield put(validateToken.failure());
  }
}

/**
* Sign Up via Facebook Flow.
*/
export function* facebookSignUpFlow(action) {
  const {
    payload: {
      data: {
        profile,
        tokenDetail: {
          accessToken: facebookAccessToken,
        },
      },
      resolve,
      reject,
    },
  } = action;
  try {
    const email = profile.publicEmail;
    const bodyForIdentities = { facebookAccessToken, email };

    const body = { facebookAccessToken };
    const params = { method: 'POST', api: 'AUTH', body: bodyForIdentities };

    yield call(retrySession, callApi, params);

    yield call(getToken, { body, type: 'facebook' });

    const token = yield call(getAuthToken);

    const decodedToken = yield call(decode, token);

    const {
      exp, iat, identity: {
        _id, facebookID, roles, status,
      },
    } = decodedToken;

    // Save a new user to API-User
    yield put(saveUser.request({
      identityID: _id,
      location: {
        type: 'Point',
        coordinates: [-97.411287, 38.971763],
      },
      ...profile,
    }));

    // Save ReferalTag if it exists
    const referalTag = yield select(selectReferalTag);

    if (referalTag) {
      const bodyTags = { identityID: _id };

      const paramsTags = {
        method: 'POST',
        api: 'TAGS',
        endpoint: `/${referalTag}/identities`,
        body: bodyTags,
        token: true,
      };

      yield call(retrySession, callApi, paramsTags);
    }
    const response = {
      _id,
      facebookID,
      roles,
      status,
      exp,
      iat,
    };
    yield put(facebookSignUp.success(response));
    resolve(response);
  } catch (error) {
    const message = error.message || 'There is a problem signing you up please try again later';
    yield put(facebookSignUp.failure(message));
    reject(message);
  }
}

/**
* Sign In via Facebook Flow.
*/
export function* facebookSignInFlow(action) {
  const { data, resolve, reject } = action.payload;
  try {
    const body = {
      facebookAccessToken: data.tokenDetail.accessToken,
    };

    yield call(getToken, { body, type: 'facebook' });

    const token = yield call(getAuthToken);

    const decodedToken = yield call(decode, token);

    const {
      exp, iat, identity: {
        _id, facebookID, roles, status,
      },
    } = decodedToken;

    const successData = {
      _id,
      facebookID,
      roles,
      status,
      exp,
      iat,
    };
    yield put(facebookSignIn.success(successData));
    resolve(successData);
  } catch (error) {
    const message = error.message || 'There is a problem signing you in please try again later';
    yield put(facebookSignIn.failure(message));
    reject(message);
  }
}

/**
* Watch Session saga.
*/
export function* watchSession() {
  yield takeEvery(SIGN_UP_REQUEST, signUpFlow);
  yield takeEvery(CONFIRM_SIGN_UP_REQUEST, confirmSignUpFlow);
  yield takeEvery(SIGN_IN_REQUEST, signInFlow);
  yield takeEvery(SIGN_OUT, signOutFlow);
  yield takeEvery(FORGOT_PASSWORD_REQUEST, forgotPasswordFlow);
  yield takeEvery(RESET_PASSWORD_REQUEST, resetPasswordFlow);
  yield takeEvery(VALIDATE_TOKEN_REQUEST, validateTokenFlow);
  yield takeEvery(FACEBOOK_SIGN_UP_REQUEST, facebookSignUpFlow);
  yield takeEvery(FACEBOOK_SIGN_IN_REQUEST, facebookSignInFlow);
  yield takeEvery(HELP_FORM_REQUEST, helpFormSubmitForm);
  yield takeEvery(CONTACT_FORM_REQUEST, contactFormSubmitForm);
}
