import React from 'react';
import Loadable from 'react-loadable';

const HeaderLoadable = Loadable({
  loader: () => import('../Header'),
  loading: () => null,
  delay: 200,
});

const SideNavLoadable = Loadable({
  loader: () => import('../SideNav'),
  loading: () => null,
  delay: 200,
});

const AlertModalLoadable = Loadable({
  loader: () => import('../AlertModal'),
  loading: () => null,
  delay: 200,
});

const AppComponent = (props) => (
  <div>
    <HeaderLoadable {...props} />
    <SideNavLoadable {...props} />
    <AlertModalLoadable {...props} />
  </div>
);

export default AppComponent;
