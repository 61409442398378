export const SIGN_UP_REQUEST = 'SIGN_UP_REQUEST';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_FAILURE = 'SIGN_UP_FAILURE';

export const CONFIRM_SIGN_UP_REQUEST = 'CONFIRM_SIGN_UP_REQUEST';
export const CONFIRM_SIGN_UP_SUCCESS = 'CONFIRM_SIGN_UP_SUCCESS';
export const CONFIRM_SIGN_UP_FAILURE = 'CONFIRM_SIGN_UP_FAILURE';

export const SIGN_IN_REQUEST = 'SIGN_IN_REQUEST';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_FAILURE = 'SIGN_IN_FAILURE';

export const SIGN_OUT = 'SIGN_OUT';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const VALIDATE_TOKEN_REQUEST = 'VALIDATE_TOKEN_REQUEST';
export const VALIDATE_TOKEN_SUCCESS = 'VALIDATE_TOKEN_SUCCESS';
export const VALIDATE_TOKEN_FAILURE = 'VALIDATE_TOKEN_FAILURE';

export const FACEBOOK_SIGN_UP_REQUEST = 'FACEBOOK_SIGN_UP_REQUEST';
export const FACEBOOK_SIGN_UP_SUCCESS = 'FACEBOOK_SIGN_UP_SUCCESS';
export const FACEBOOK_SIGN_UP_FAILURE = 'FACEBOOK_SIGN_UP_FAILURE';

export const FACEBOOK_SIGN_IN_REQUEST = 'FACEBOOK_SIGN_IN_REQUEST';
export const FACEBOOK_SIGN_IN_SUCCESS = 'FACEBOOK_SIGN_IN_SUCCESS';
export const FACEBOOK_SIGN_IN_FAILURE = 'FACEBOOK_SIGN_IN_FAILURE';

export const UPDATE_FACEBOOK_ID = 'UPDATE_FACEBOOK_ID';

export const HELP_FORM_REQUEST = 'HELP_FORM_REQUEST';
export const HELP_FORM_SUCCESS = 'HELP_FORM_SUCCESS';
export const HELP_FORM_FAILURE = 'HELP_FORM_FAILURE';

export const CONTACT_FORM_REQUEST = 'CONTACT_FORM_REQUEST';
export const CONTACT_FORM_SUCCESS = 'CONTACT_FORM_SUCCESS';
export const CONTACT_FORM_FAILURE = 'CONTACT_FORM_FAILURE';
