import { fromJS } from 'immutable';

const initialState = fromJS({
  messages: {},
});

export default (state = initialState, action) => {
  if (action.entities) {
    return state.mergeDeep(action.entities);
  }
  return state;
};
