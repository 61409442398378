import { matchPath } from 'react-router-dom';
import { fromJS } from 'immutable';
import { createSelector } from 'reselect';

import { makeSelectSessionUserId } from '../session/selectors';
import { selectUsers } from '../users/selectors';
import { sameDay } from '../../utils/dates';

export const getEntities = (state) => state.get('entities');

const pathRegex = /^\/inbox\/.+/;

export const getRoutermatch = (state, props) => {
  if (props.match && props.match.path === '/inbox/:id') {
    return props.match;
    // if props are coming form a component outside of
    // conversation route
  } if (pathRegex.test(props.location.pathname)) {
    return matchPath(props.location.pathname, {
      path: '/inbox/:id',
    });
  }
  return null;
};

export const makeSelectParticipantId = () => createSelector(
  getRoutermatch,
  (match) => {
    return match && match.params ? match.params.id : null;
  },
);

export const makeSelectParticipant = () => createSelector(
  makeSelectParticipantId(),
  selectUsers,
  (participantId, users) => {
    const participant = participantId ? users.get(participantId) : null;
    return participant ? participant.set('identityID', participantId) : null;
  },
);

export const makeSelectConversationMessages = () => createSelector(
  makeSelectSessionUserId(),
  makeSelectParticipantId(),
  getEntities,
  selectUsers,
  (userId, participantId, entities, users) => {
    return entities.get('messages')
      .toList()
      .filter((m) => (m.get('from') === userId && m.getIn(['to', 'id']) === participantId)
        || (m.get('from') === participantId && m.getIn(['to', 'id']) === userId))
      .map((message) => {
        const createdAt = new Date(message.get('createdAt'));
        return message.set(
          'time',
          createdAt.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }),
        );
      })
      .map((message) => {
        const from = message.get('from');
        return message
          .set('from', users.get(from) ? users.get(from) : fromJS({}))
          .setIn(['from', 'identityID'], from);
      })
      .sortBy((message) => message.get('createdAt'));
  },
);

export const makeSelectInbox = () => createSelector(
  makeSelectSessionUserId(),
  getEntities,
  selectUsers,
  (userId, entities, users) => {
    const latestMessages = entities
      .get('messages')
      .valueSeq()
      .groupBy(
        (m) => (m.get('from') === userId ? m.getIn(['to', 'id']) : m.get('from')),
      )
      .map((messagesByParticipant) => messagesByParticipant.sortBy((message) => message.get('createdAt')).last());

    const latestMessagesWithParticipant = latestMessages
      .map((message, participantId) => {
        const participant = users.get(participantId)
          ? users.get(participantId)
          : fromJS({ firstName: '', lastName: '', avatar: '' });
        return message.set('participant', participant.set('identityID', participantId));
      })
      .valueSeq();

    const messagesGroupByDate = latestMessagesWithParticipant
      .sortBy((message) => message.get('createdAt'))
      .map((message) => {
        const createdAt = new Date(message.get('createdAt'));
        return message.set(
          'time',
          createdAt.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }),
        );
      })
      .reverse()
      .groupBy((message) => {
        const today = new Date();
        const yesterday = new Date(Date.now() - 86400000);
        const createdAt = new Date(message.get('createdAt'));
        if (sameDay(createdAt, today)) return 'today';
        if (sameDay(createdAt, yesterday)) return 'yesterday';
        return createdAt.toLocaleDateString('en-US');
      });

    return messagesGroupByDate;
  },
);
