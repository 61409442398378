import {
  SIGN_UP_REQUEST,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAILURE,
  CONFIRM_SIGN_UP_REQUEST,
  CONFIRM_SIGN_UP_SUCCESS,
  CONFIRM_SIGN_UP_FAILURE,
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,
  SIGN_OUT,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  VALIDATE_TOKEN_REQUEST,
  VALIDATE_TOKEN_SUCCESS,
  VALIDATE_TOKEN_FAILURE,
  FACEBOOK_SIGN_UP_REQUEST,
  FACEBOOK_SIGN_UP_SUCCESS,
  FACEBOOK_SIGN_UP_FAILURE,
  FACEBOOK_SIGN_IN_REQUEST,
  FACEBOOK_SIGN_IN_SUCCESS,
  FACEBOOK_SIGN_IN_FAILURE,
  UPDATE_FACEBOOK_ID,
  HELP_FORM_REQUEST,
  HELP_FORM_SUCCESS,
  HELP_FORM_FAILURE,
  CONTACT_FORM_REQUEST,
  CONTACT_FORM_SUCCESS,
  CONTACT_FORM_FAILURE,
} from './constants';

/**
* Sign Up actions.
*/
export const signUp = {
  request: (data) => ({ type: SIGN_UP_REQUEST, payload: data }),
  success: (data) => ({ type: SIGN_UP_SUCCESS, payload: data }),
  failure: (error) => ({ type: SIGN_UP_FAILURE, error }),
};

/**
* Confirm Sign Up actions.
*/
export const confirmSignUp = {
  request: (token) => ({ type: CONFIRM_SIGN_UP_REQUEST, payload: token }),
  success: () => ({ type: CONFIRM_SIGN_UP_SUCCESS }),
  failure: (error) => ({ type: CONFIRM_SIGN_UP_FAILURE, error }),
};

/**
* Sign In actions.
*/
export const signIn = {
  request: (data) => ({ type: SIGN_IN_REQUEST, payload: data }),
  success: (data) => ({ type: SIGN_IN_SUCCESS, payload: data }),
  failure: (error) => ({ type: SIGN_IN_FAILURE, error }),
};

/**
* Sign Out action.
*/
export const signOut = () => ({ type: SIGN_OUT });

/**
* Forgot Password actions.
*/
export const forgotPassword = {
  request: (data) => ({ type: FORGOT_PASSWORD_REQUEST, payload: data }),
  success: () => ({ type: FORGOT_PASSWORD_SUCCESS }),
  failure: (error) => ({ type: FORGOT_PASSWORD_FAILURE, error }),
};

/**
* Reset Password actions.
*/
export const resetPassword = {
  request: (data) => ({ type: RESET_PASSWORD_REQUEST, payload: data }),
  success: () => ({ type: RESET_PASSWORD_SUCCESS }),
  failure: (error) => ({ type: RESET_PASSWORD_FAILURE, error }),
};

/**
* Validate Token actions.
*/
export const validateToken = {
  request: () => ({ type: VALIDATE_TOKEN_REQUEST }),
  success: (data) => ({ type: VALIDATE_TOKEN_SUCCESS, payload: data }),
  failure: () => ({ type: VALIDATE_TOKEN_FAILURE }),
};

/**
* Sign Up via Facebook actions.
*/
export const facebookSignUp = {
  request: (data) => ({ type: FACEBOOK_SIGN_UP_REQUEST, payload: data }),
  success: (data) => ({ type: FACEBOOK_SIGN_UP_SUCCESS, payload: data }),
  failure: (error) => ({ type: FACEBOOK_SIGN_UP_FAILURE, error }),
};

/**
* Sign In via Facebook actions.
*/
export const facebookSignIn = {
  request: (data) => ({ type: FACEBOOK_SIGN_IN_REQUEST, payload: data }),
  success: (data) => ({ type: FACEBOOK_SIGN_IN_SUCCESS, payload: data }),
  failure: (error) => ({ type: FACEBOOK_SIGN_IN_FAILURE, error }),
};

/**
* Update Facebook Id action.
*/
export const updateFacebookId = (data) => ({ type: UPDATE_FACEBOOK_ID, payload: data });

/**
 * Help Form actions.
 */
export const helpForm = {
  request: (data) => ({ type: HELP_FORM_REQUEST, payload: data }),
  success: () => ({ type: HELP_FORM_SUCCESS }),
  failure: (error) => ({ type: HELP_FORM_FAILURE, error }),
};

/**
 * Contact Form actions.
 */
export const contactForm = {
  request: (data) => ({ type: CONTACT_FORM_REQUEST, payload: data }),
  success: () => ({ type: CONTACT_FORM_SUCCESS }),
  failure: (error) => ({ type: CONTACT_FORM_FAILURE, error }),
};
