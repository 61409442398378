import { fromJS } from 'immutable';
import {
  SAVE_REFERAL_TAG_REQUEST,
  SAVE_REFERAL_TAG_SUCCESS,
  SAVE_REFERAL_TAG_FAILURE,
  GET_REFERAL_TAG_REQUEST,
  GET_REFERAL_TAG_SUCCESS,
  GET_REFERAL_TAG_FAILURE,
} from './constants';

/**
* App Initial State.
*/
export const initialState = fromJS({
  referalTag: '',
  loading: {
    getReferalTag: false,
    saveReferalTag: false,
  },
  errors: {
    getReferalTag: false,
    saveReferalTag: false,
  },
});

export default (state = initialState, action) => {
  switch (action.type) {
  /**
    * GET_REFERAL_TAG
    */
  case GET_REFERAL_TAG_REQUEST:
    return state.setIn(['loading', 'getReferalTag'], true).setIn(['errors', 'getReferalTag'], false);
  case GET_REFERAL_TAG_SUCCESS:
    return state.set('referalTag', action.payload).setIn(['loading', 'getReferalTag'], false);
  case GET_REFERAL_TAG_FAILURE:
    return state.setIn(['loading', 'getReferalTag'], false).setIn(['errors', 'getReferalTag'], action.error);
    /**
    * SAVE_REFERAL_TAG
    */
  case SAVE_REFERAL_TAG_REQUEST:
    return state
      .set('referalTag', '')
      .setIn(['loading', 'saveReferalTag'], true)
      .setIn(['errors', 'saveReferalTag'], false);
  case SAVE_REFERAL_TAG_SUCCESS:
    return state.set('referalTag', action.payload).setIn(['loading', 'saveReferalTag'], false);
  case SAVE_REFERAL_TAG_FAILURE:
    return state.setIn(['loading', 'saveReferalTag'], false).setIn(['errors', 'saveReferalTag'], action.error);
  default:
    return state;
  }
};
