import React from 'react';
import * as OfflinePluginRuntime from 'offline-plugin/runtime';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { AppContainer } from 'react-hot-loader';
import { createBrowserHistory } from 'history';
import { ConnectedRouter } from 'connected-react-router/immutable';
import { Redirect, Route, Switch } from 'react-router-dom';
import browserUpdate from 'browser-update';
import MuiThemeProvider from '@material-ui/core/es/styles/MuiThemeProvider';
import config from './config';
import theme from './styles/theme';
import configureStore from './store/configure';
import rootSaga from './store/sagas';
import App from './containers/App';
import routes from './routes';
import AsyncRoute from './containers/AsyncRoute';
import './icons';

if (config.NODE_ENV === 'production') {
  OfflinePluginRuntime.install({
    onUpdateReady: () => {
      // Tells to new SW to take control immediately
      OfflinePluginRuntime.applyUpdate();
    },
    onUpdated: () => {
      // Reload the webpage to load into the new version
      window.location.reload();
    },
  });
}

browserUpdate({
  required: {
    e: 16, f: -3, o: -3, s: -1, c: -3,
  },
  reminder: 24,
  reminderClosed: 24 * 7,
  unsupported: true,
  insecure: true,
  test: false,
  text: 'Your {brow_name} browser is old. This website may not function properly. Please <a{up_but}>update</a> or <a{ignore_but}>ignore</a>.',
});

const history = createBrowserHistory();
const store = configureStore({}, history);

store.runSaga(rootSaga);

const root = document.getElementById('root');

const renderApp = () => (
  <AppContainer>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <MuiThemeProvider theme={theme}>
          <div>
            <App />
            <Switch>
              {routes.map((route) => (
                <Route
                  key={route.path}
                  path={route.path}
                  exact
                  component={(props) => (
                    <AsyncRoute
                      authenticated={route.authenticated}
                      visibleOnlyGuest={route.visibleOnlyGuest}
                      footer={route.footer}
                      bottomNav={route.bottomNav}
                      container={import(`./routes/${route.route}/index.js`)}
                      {...props}
                    />
                  )}
                />
              ))}
              <Redirect to="/" />
            </Switch>
          </div>
        </MuiThemeProvider>
      </ConnectedRouter>
    </Provider>
  </AppContainer>
);

render(renderApp(), root);

if (module.hot) {
  module.hot.accept('routes', () => {
    // eslint-disable-next-line
    require('./routes');
    render(renderApp(), root);
  });
}
