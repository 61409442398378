import { library } from '@fortawesome/fontawesome-svg-core';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons/faSignInAlt';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt';
import { faUserAlt } from '@fortawesome/free-solid-svg-icons/faUserAlt';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons/faArrowUp';
import { faThumbsDown } from '@fortawesome/free-solid-svg-icons/faThumbsDown';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons/faThumbsUp';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers';
import { faCalendar } from '@fortawesome/free-solid-svg-icons/faCalendar';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { faListUl } from '@fortawesome/free-solid-svg-icons/faListUl';

import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons/faFacebookSquare';
import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';

library.add(
  faSignInAlt,
  faSignOutAlt,
  faUserAlt,
  faFacebookSquare,
  faArrowUp,
  faFacebook,
  faThumbsDown,
  faThumbsUp,
  faUser,
  faUsers,
  faCalendar,
  faDownload,
  faTwitter,
  faListUl,
);
