import { createSelector } from 'reselect';
import { Map } from 'immutable';
import { selectSession } from '../session/selectors';

/**
* Users selector.
*/
export const selectUsers = (state) => state.get('users');

/**
* Loading selector.
*/
export const selectLoading = (state) => state.getIn(['users', 'loading']);

/**
* Errors selector.
*/
export const selectErrors = (state) => state.getIn(['users', 'errors']);

/**
* Current User selector.
*/
export const makeSelectCurrentUser = () => createSelector(
  [selectSession, selectUsers],
  (sessionState, usersState) => {
    let currentUser = usersState.get(sessionState.getIn(['user', '_id']));
    const facebookID = sessionState.getIn(['user', 'facebookID']);

    if (currentUser && currentUser.get('birthday')) {
      const birthday = currentUser.get('birthday');
      currentUser = currentUser.set('birthMonth', Number((new Date(birthday)).getMonth()))
        .set('birthYear', Number((new Date(birthday).getFullYear().toString())));
    }

    if (facebookID && currentUser) {
      return currentUser.set(
        'Facebook',
        `https://www.facebook.com/${facebookID}`,
      );
    }

    return currentUser;
  },
);

/**
 * Signup pending user selector
*/

export const makeSelectSignupUser = () => createSelector(
  [selectSession, selectUsers],
  (sessionState, usersState) => {
    let user = sessionState.get('user');

    if (user.has('email') && user.get('method') === 'email') {
      user = user.set('publicEmail', user.get('email'))
        .delete('email');
    }
    if (user.get('method') === 'facebook') {
      user = usersState.get(sessionState.getIn(['user', '_id']));
    }
    user = user.set('gender', 'male');
    return user && Map.isMap(user) ? user : Map();
  },
);

/**
* Get all users.
*/

export const makeSelectAllUsers = () => createSelector(
  selectUsers,
  (usersState) => {
    return usersState.delete('loading').delete('errors');
  },
);

/**
* User by Id selector.
*/

export const makeSelectUser = (state, props) => createSelector(
  [selectUsers],
  (usersState) => {
    const { id } = props.match.params;
    return usersState.get(id);
  },
);

/**
* Loading Update User selector.
*/
export const makeSelectUpdateUserIsLoading = () => createSelector(
  selectLoading,
  (loadingState) => loadingState.get('updateUser'),
);

/**
* Error Update User selector.
*/
export const makeSelectUpdateUserError = () => createSelector(
  selectErrors,
  (errorsState) => errorsState.get('updateUser'),
);

/**
* Loading Update User selector.
*/
export const makeSelectUpdatePasswordUpdated = () => createSelector(
  selectLoading,
  (loadingState) => loadingState.get('updatePassword'),
);

/**
* Error Update User selector.
*/
export const makeSelectUpdatePasswordError = () => createSelector(
  selectErrors,
  (errorsState) => errorsState.get('updatePassword'),
);

/**
* Loading Link to Facebook selector.
*/
export const makeSelectLinkToFacebookLoading = () => createSelector(
  selectLoading,
  (loadingState) => loadingState.get('linkToFacebook'),
);

/**
* Error Link to Facebook selector.
*/
export const makeSelectLinkToFacebookError = () => createSelector(
  selectErrors,
  (errorsState) => errorsState.get('linkToFacebook'),
);
