import { fromJS } from 'immutable';
import {
  CALCULATE_MAP_STATE,
  SET_MARKERS_FILTER,
  GET_MARKERS_REQUEST,
  GET_MARKERS_SUCCESS,
  GET_MARKERS_FAILURE,
  GET_GEO_LOCATION_REQUEST,
  GET_GEO_LOCATION_SUCCESS,
  GET_GEO_LOCATION_FAILURE,
  SET_MAP_CENTER_STATE,
  SET_MAP_ZOOM_STATE,
} from './constants';

/**
* App Initial State.
*/
export const initialState = fromJS({
  data: {
    bounds: {
      nw: {},
      se: {},
      sw: {},
      ne: {},
    },
    center: {
      lat: 38,
      lng: -110,
    },
    zoom: 5,
  },
  filters: {
    people: {
      sport: 'all',
      level: 'all',
    },
    event: {
      sport: 'all',
      level: 'all',
    },
    group: {
      sport: 'all',
      level: 'all',
    },
  },
  markers: {
    people: [],
    event: [],
    group: [],
  },
  userGeoLocation: {
    lat: 38.971763,
    lng: -97.411287,
  },
  loading: {
    userGeoLocation: false,
    isLoaded: false,
  },
  errors: {},
});

export default (state = initialState, action) => {
  switch (action.type) {
  /**
    * CALCULATE_MAP_STATE
    */
  case CALCULATE_MAP_STATE:
    return state
      .set('data', fromJS(action.payload));
    /**
    * SET_MAP_ZOOM_STATE
    */
  case SET_MAP_ZOOM_STATE:
    return state
      .setIn(['data', 'zoom'], fromJS(action.payload));
    /**
    * SET_MAP_ZOOM_STATE
    */
  case SET_MAP_CENTER_STATE:
    return state
      .setIn(['data', 'center'], fromJS(action.payload))
      .setIn(['loading', 'isLoaded'], true);
    /**
    * SET_MARKERS_FILTER
    */
  case SET_MARKERS_FILTER: {
    const { type, by, data } = action.payload;
    return state
      .setIn(['filters', type, by], data);
  }
  /**
    * GET_MARKERS
    */
  case GET_MARKERS_REQUEST:
    return state
      .setIn(['markers', 'people'], [])
      .setIn(['markers', 'event'], [])
      .setIn(['markers', 'group'], [])
      .setIn(['errors', 'getMarkers'], false)
      .setIn(['loading', 'userGeoLocation'], true);
  case GET_MARKERS_SUCCESS:
    return state
      .set('markers', fromJS(action.payload)
        .groupBy((marker) => marker.get('type')))
      .setIn(['loading', 'userGeoLocation'], false);
  case GET_MARKERS_FAILURE:
    return state
      .setIn(['errors', 'getMarkers'], action.error)
      .setIn(['loading', 'userGeoLocation'], false);
    /**
    * GET_GEO_LOCATION
    */
  case GET_GEO_LOCATION_REQUEST:
    return state
      .setIn(['loading', 'userGeoLocation'], true)
      .setIn(['loading', 'isLoaded'], false);

  case GET_GEO_LOCATION_SUCCESS:
    return state
      .set('userGeoLocation', fromJS(action.payload))
      .setIn(['data', 'center'], fromJS(action.payload))
      .setIn(['loading', 'userGeoLocation'], false)
      .setIn(['loading', 'isLoaded'], true);

  case GET_GEO_LOCATION_FAILURE:
    return state
      .setIn(['errors', 'userGeoLocation'], action.error)
      .setIn(['loading', 'userGeoLocation'], false)
      .setIn(['loading', 'isLoaded'], false);
  default:
    return state;
  }
};
