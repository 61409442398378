import { createSelector } from 'reselect';

/**
* Session selector.
*/
export const selectSession = (state) => state.get('session');

/**
* Loading selector.
*/
export const selectLoading = (state) => state.getIn(['session', 'loading']);

/**
* Errors selector.
*/
export const selectErrors = (state) => state.getIn(['session', 'errors']);

/**
* helpForm selector.
*/
export const selectHelpForm = (state) => state.getIn(['session', 'helpForm']);

/**
* contactForm selector.
*/
export const selectContactForm = (state) => state.getIn(['session', 'contactForm']);

/**
* authenticating selector.
*/
export const makeSelectAuthenticating = () => createSelector(
  selectSession,
  (sessionState) => sessionState.get('authenticating'),
);

/**
* IsAuthenticated selector.
*/
export const makeSelectIsAuthenticated = () => createSelector(
  selectSession,
  (sessionState) => sessionState.get('isAuthenticated'),
);

/**
* Session User Data selector.
*/
export const makeSelectSessionUserData = () => createSelector(
  selectSession,
  (sessionState) => sessionState.get('user'),
);

/**
* Session User Id.
*/
export const makeSelectSessionUserId = () => createSelector(
  selectSession,
  (sessionState) => sessionState.getIn(['user', '_id']),
);

/**
 * Session User Signup Method.
 */
export const makeSelectSessionUserSignupMethod = () => createSelector(
  selectSession,
  (sessionState) => sessionState.getIn(['user', 'method']),
);

/**
* IsEmailPasswordResetSent selector.
*/
export const makeSelectIsEmailPasswordResetSent = () => createSelector(
  selectSession,
  (sessionState) => sessionState.get('isEmailPasswordResetSent'),
);

/**
* IsPasswordReset selector.
*/
export const makeSelectIsPasswordReset = () => createSelector(
  selectSession,
  (sessionState) => sessionState.get('isPasswordReset'),
);

/**
* Loading Sign Up selector.
*/
export const makeSelectSignUpIsLoading = () => createSelector(
  selectLoading,
  (loadingState) => loadingState.get('signUp'),
);

/**
* Loading Sign Up Confirmation selector.
*/
export const makeSelectConfirmSignUpIsLoading = () => createSelector(
  selectLoading,
  (loadingState) => loadingState.get('confirmSignUp'),
);

/**
* Loading Sign In selector.
*/
export const makeSelectSignInIsLoading = () => createSelector(
  selectLoading,
  (loadingState) => loadingState.get('signIn'),
);

/**
* Loading Forgot Password selector.
*/
export const makeSelectForgotPasswordIsLoading = () => createSelector(
  selectLoading,
  (loadingState) => loadingState.get('forgotPassword'),
);

/**
* Loading Reset Password selector.
*/
export const makeSelectResetPasswordIsLoading = () => createSelector(
  selectLoading,
  (loadingState) => loadingState.get('resetPassword'),
);

/**
* Loading Sign Up via Facebook selector.
*/
export const makeSelectFacebookSignUpIsLoading = () => createSelector(
  selectLoading,
  (loadingState) => loadingState.get('facebookSignUp'),
);

/**
* Loading Sign In via Facebook selector.
*/
export const makeSelectFacebookSignInIsLoading = () => createSelector(
  selectLoading,
  (loadingState) => loadingState.get('facebookSignIn'),
);

/**
* Error Sign Up selector.
*/
export const makeSelectSignUpError = () => createSelector(
  selectErrors,
  (errorsState) => errorsState.get('signUp'),
);

/**
* Error Sign Up Confirmation selector.
*/
export const makeSelectConfirmSignUpError = () => createSelector(
  selectErrors,
  (errorsState) => errorsState.get('confirmSignUp'),
);

/**
* Error Sign In selector.
*/
export const makeSelectSignInError = () => createSelector(
  selectErrors,
  (errorsState) => errorsState.get('signIn'),
);

/**
* Error Forgot Password selector.
*/
export const makeSelectForgotPasswordError = () => createSelector(
  selectErrors,
  (errorsState) => errorsState.get('forgotPassword'),
);

/**
* Error Reset Password selector.
*/
export const makeSelectResetPasswordError = () => createSelector(
  selectErrors,
  (errorsState) => errorsState.get('resetPassword'),
);

/**
* Error Sign Up via Facebook selector.
*/
export const makeSelectFacebookSignUpError = () => createSelector(
  selectErrors,
  (errorsState) => errorsState.get('facebookSignUp'),
);

/**
* Error Sign In via Facebook selector.
*/
export const makeSelectFacebookSignInError = () => createSelector(
  selectErrors,
  (errorsState) => errorsState.get('facebookSignIn'),
);

/**
* helpForm isSubmitting selector.
*/
export const makeSelectHelpFormIsSubmitting = () => createSelector(
  selectHelpForm,
  (helpForm) => helpForm.get('isSubmitting'),
);

/**
 * helpForm isSubmitted selector.
 */
export const makeSelectHelpFormIsSubmitted = () => createSelector(
  selectHelpForm,
  (helpForm) => helpForm.get('isSubmitted'),
);

/**
 * helpForm errors selector.
 */
export const makeSelectHelpFormErrors = () => createSelector(
  selectHelpForm,
  (helpForm) => helpForm.get('errors'),
);

/**
 * contactForm isSubmitting selector.
 */
export const makeSelectContactFormIsSubmitting = () => createSelector(
  selectContactForm,
  (contactForm) => contactForm.get('isSubmitting'),
);

/**
 * contactForm isSubmitted selector.
 */
export const makeSelectContactFormIsSubmitted = () => createSelector(
  selectContactForm,
  (contactForm) => contactForm.get('isSubmitted'),
);

/**
 * contactForm errors selector.
 */
export const makeSelectContactFormErrors = () => createSelector(
  selectContactForm,
  (contactForm) => contactForm.get('errors'),
);
