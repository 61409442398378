const markers = [
    {
        "_id": "e79e31c2-c4f6-411d-8627-e10f9231c8e2",
        "type": "group",
        "name": "SFC Denver",
        "url": "https://sfcusa.org/denver",
        "location": {
            "lat": "39.61721",
            "lng": "-104.95081"
        },
        "category": [
            "acts",
            "sfc"
        ],
        "skills": [
            {
                "sport": "Snowboarding",
                "level": "all"
            },
            {
                "sport": "Skiing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "81fa87c7-46c2-421d-9202-a35f4f8938a3",
        "type": "group",
        "name": "Wakewell Lake Lewisville",
        "url": "https://www.facebook.com/groups/218424428256618",
        "location": {
            "lat": "33.1639273",
            "lng": "-97.1395446"
        },
        "category": [
            "acts",
            "wakewell"
        ],
        "skills": [
            {
                "sport": "Wakeboarding",
                "level": "all"
            }
        ]
    },
    {
        "_id": "24bd1d94-3000-4587-b2f6-1e0f9f60cc10",
        "type": "group",
        "name": "JSAW",
        "url": "https://jsaw.org/",
        "location": {
            "lat": "44.86871",
            "lng": "-93.3592443"
        },
        "category": [
            "acts",
            "other"
        ],
        "skills": [
            {
                "sport": "Skateboarding",
                "level": "all"
            },
            {
                "sport": "Skiing",
                "level": "all"
            },
            {
                "sport": "Wakeboarding",
                "level": "all"
            }
        ]
    },
    {
        "_id": "5e3dba57-d33c-4f64-ada3-f399cc30fb09",
        "type": "group",
        "name": "Inspired to Become",
        "url": "https://inspired2become.org",
        "location": {
            "lat": "-25.7479",
            "lng": "28.2293"
        },
        "category": [
            "acts",
            "other"
        ],
        "skills": [
            {
                "sport": "Skateboarding",
                "level": "all"
            },
            {
                "sport": "Inline Skating",
                "level": "all"
            },
            {
                "sport": "Surfing",
                "level": "all"
            },
            {
                "sport": "Skim Boarding",
                "level": "all"
            }
        ]
    },
    {
        "_id": "b69a5db0-6186-4e0c-ab40-1a493044dc97",
        "type": "group",
        "name": "Steven Dresselhaus",
        "url": "https://www.reconciliamar.org",
        "location": {
            "lat": "24.129072",
            "lng": "-110.333348"
        },
        "category": [
            "acts",
            "other"
        ],
        "skills": [
            {
                "sport": "Kayaking",
                "level": "all"
            }
        ]
    },
    {
        "_id": "509a931c-4a07-436c-a5fa-4b4d4106e34f",
        "type": "group",
        "name": "Kibō Network",
        "url": "https://www.linkedin.com/in/joerg-peter-koch-222946a1/",
        "location": {
            "lat": "43.189054",
            "lng": "140.996744"
        },
        "category": [
            "acts",
            "other"
        ],
        "skills": [
            {
                "sport": "Surfing",
                "level": "all"
            },
            {
                "sport": "Snowboarding",
                "level": "all"
            },
            {
                "sport": "Skiing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "9310fa2b-24c9-4a34-8edd-867117426e47",
        "type": "group",
        "name": "Jake's Bike and Climb",
        "url": "https://www.mountainproject.com/user/200416385/jake-aspacher",
        "location": {
            "lat": "33.466571",
            "lng": "-84.596773"
        },
        "category": [
            "acts",
            "other"
        ],
        "skills": [
            {
                "sport": "Mountain Biking",
                "level": "all"
            },
            {
                "sport": "Climbing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "8788e3dc-6190-4281-83df-ca3217235253",
        "type": "group",
        "name": "Christian skaters Uganda",
        "url": "https://www.instagram.com/p/BwqsJROgpLq/?igshid=17cqnd298bd7l",
        "location": {
            "lat": "0.347596",
            "lng": "32.58252"
        },
        "category": [
            "acts",
            "cskate"
        ],
        "skills": [
            {
                "sport": "Skateboarding",
                "level": "all"
            },
            {
                "sport": "BMX",
                "level": "all"
            }
        ]
    },
    {
        "_id": "ae6a810d-7ca1-4194-8648-d5719c140f10",
        "type": "group",
        "name": "Surf Church Collective",
        "url": "https://surf.church",
        "location": {
            "lat": "29.28581",
            "lng": "-81.05589"
        },
        "category": [
            "acts",
            "other"
        ],
        "skills": [
            {
                "sport": "Surfing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "fdd54aec-9787-46f9-8946-a4123c555c12",
        "type": "group",
        "name": "Wilderness Ministry",
        "url": "https://home.srom.org/",
        "location": {
            "lat": "41.31137",
            "lng": "-105.5911"
        },
        "category": [
            "acts",
            "other"
        ],
        "skills": [
            {
                "sport": "Climbing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "4d7194a3-a7e5-4508-9d93-b1bbe4e2be7f",
        "type": "group",
        "name": "Surfing The Nations (STN)",
        "url": "https://stn.global",
        "location": {
            "lat": "21.4389",
            "lng": "158.0001"
        },
        "category": [
            "acts",
            "other"
        ],
        "skills": [
            {
                "sport": "Surfing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "bc2edd5e-1af3-4a6b-a6e9-64f533dee9e4",
        "type": "group",
        "name": "Whitecap Kayak",
        "url": "https://whitecapkayak.com",
        "location": {
            "lat": "46.658889",
            "lng": "-90.919444"
        },
        "category": [
            "acts",
            "other"
        ],
        "skills": [
            {
                "sport": "Kayaking",
                "level": "all"
            }
        ]
    },
    {
        "_id": "470f7bd7-b19b-4def-bdf4-54384614c71c",
        "type": "group",
        "name": "The Inside Youth Outreach",
        "url": "https://theinside.ca/",
        "location": {
            "lat": "51.050743",
            "lng": "-114.075952"
        },
        "category": [
            "acts",
            "other"
        ],
        "skills": [
            {
                "sport": "Skateboarding",
                "level": "all"
            },
            {
                "sport": "Snowboarding",
                "level": "all"
            }
        ]
    },
    {
        "_id": "770c0a62-20e2-4255-81df-786c17ab1dcf",
        "type": "group",
        "name": "The Wake Movement",
        "url": "https://www.thewakemovement.com/",
        "location": {
            "lat": "36.37285",
            "lng": "-94.20882"
        },
        "category": [
            "acts",
            "other"
        ],
        "skills": [
            {
                "sport": "Wakeboarding",
                "level": "all"
            }
        ]
    },
    {
        "_id": "74f52fb3-4c27-4309-a666-545f3507a8b3",
        "type": "group",
        "name": "Into the Wilderness",
        "url": "https://intothewilderness.eu",
        "location": {
            "lat": "45.73736",
            "lng": "7.3166"
        },
        "category": [
            "acts",
            "other"
        ],
        "skills": [
            {
                "sport": "Climbing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "af50aa5a-6c1e-4fcc-b882-35ca54921f1d",
        "type": "group",
        "name": "Skatelife- Young Life",
        "url": "https://www.skatelife.ca/",
        "location": {
            "lat": "51.0447",
            "lng": "114.0719"
        },
        "category": [
            "acts",
            "other"
        ],
        "skills": [
            {
                "sport": "Skateboarding",
                "level": "all"
            }
        ]
    },
    {
        "_id": "fb399683-92e6-45f3-9422-28c41671ddff",
        "type": "group",
        "name": "Ride Nature",
        "url": "https://www.ridenature.org/",
        "location": {
            "lat": "26.142",
            "lng": "81.7948"
        },
        "category": [
            "acts",
            "other"
        ],
        "skills": [
            {
                "sport": "Skateboarding",
                "level": "all"
            },
            {
                "sport": "Surfing",
                "level": "all"
            },
            {
                "sport": "Wakeboarding",
                "level": "all"
            }
        ]
    },
    {
        "_id": "e98eb66f-7c0b-4766-84f8-62a0e1c600d4",
        "type": "group",
        "name": "Royal Camps",
        "url": "https://WeAreROYAL.com",
        "location": {
            "lat": "36.07264",
            "lng": "-79.79198"
        },
        "category": [
            "acts",
            "other"
        ],
        "skills": [
            {
                "sport": "Skateboarding",
                "level": "all"
            },
            {
                "sport": "BMX",
                "level": "all"
            },
            {
                "sport": "Mountain Biking",
                "level": "all"
            }
        ]
    },
    {
        "_id": "9b358846-dd83-4f09-984a-2ac746e5e39a",
        "type": "group",
        "name": "Solid Rock - Climbers for Christ Mexico",
        "url": "https://www.climbersforchrist.org/",
        "location": {
            "lat": "19.41361",
            "lng": "-99.03306"
        },
        "category": [
            "acts",
            "cfc"
        ],
        "skills": [
            {
                "sport": "Climbing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "36dba017-0a76-4025-b27d-6fd7a0349ebc",
        "type": "group",
        "name": "Solid Rock - Climbers for Christ",
        "url": "https://www.climbersforchrist.org/",
        "location": {
            "lat": "19.2",
            "lng": "-96.13333"
        },
        "category": [
            "acts",
            "cfc"
        ],
        "skills": [
            {
                "sport": "Climbing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "ea27ee0c-26a7-4208-8c6b-76cef1f0e0ce",
        "type": "group",
        "name": "LivExtreme",
        "url": "https://www.livextreme.at",
        "location": {
            "lat": "48.30639",
            "lng": "14.28611"
        },
        "category": [
            "acts",
            "other"
        ],
        "skills": [
            {
                "sport": "Motocross",
                "level": "all"
            }
        ]
    },
    {
        "_id": "737030bd-d863-4486-b621-d1837ff1bbcf",
        "type": "group",
        "name": "Battleground Skate House",
        "url": "http://www.battlegroundskatehouse.com/",
        "location": {
            "lat": "42.73254",
            "lng": "-84.55553"
        },
        "category": [
            "acts",
            "other"
        ],
        "skills": [
            {
                "sport": "Skateboarding",
                "level": "all"
            }
        ]
    },
    {
        "_id": "982746d2-be26-4b35-bd1d-4fe756419660",
        "type": "group",
        "name": "Trailstoke",
        "url": "http://trailstoke.org/",
        "location": {
            "lat": "48.5126",
            "lng": "-122.61267"
        },
        "category": [
            "acts",
            "other"
        ],
        "skills": [
            {
                "sport": "Mountain Biking",
                "level": "all"
            }
        ]
    },
    {
        "_id": "2aa99301-a12a-4ff4-bc52-b62161b24a52",
        "type": "group",
        "name": "SFC Denver",
        "url": "https://sfcusa.org/",
        "location": {
            "lat": "39.61721",
            "lng": "-104.95081"
        },
        "category": [
            "acts",
            "other"
        ],
        "skills": [
            {
                "sport": "Snowboarding",
                "level": "all"
            }
        ]
    },
    {
        "_id": "562dbef5-6d5d-45ab-9e78-9b676ea523e1",
        "type": "group",
        "name": "Christian Skaters South Africa",
        "url": "https://www.christianskaters.co.za/",
        "location": {
            "lat": "-33.9131286",
            "lng": "18.0955722"
        },
        "category": [
            "acts",
            "other"
        ],
        "skills": [
            {
                "sport": "Inline Skating",
                "level": "all"
            }
        ]
    },
    {
        "_id": "8a6d5517-d532-4174-9821-e45d15ec10b9",
        "type": "group",
        "name": "Walking on Water",
        "url": "https://walkingonwater.com/",
        "location": {
            "lat": "33.42697",
            "lng": "-117.61199"
        },
        "category": [
            "acts",
            "other"
        ],
        "skills": [
            {
                "sport": "Surfing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "2207c640-bc1a-425b-8091-554188ebe451",
        "type": "group",
        "name": "Christian Surfers Thailand",
        "url": "https://www.christiansurfers.net/thailand",
        "location": {
            "lat": "8.38373",
            "lng": "98.45123"
        },
        "category": [
            "acts",
            "other"
        ],
        "skills": [
            {
                "sport": "Surfing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "9a74878c-9ac3-4ce5-aab3-5cdb63f1502e",
        "type": "group",
        "name": "Climbers for Christ: Mexico Chapter",
        "url": "https://www.climbersforchrist.org/",
        "location": {
            "lat": "19.51353",
            "lng": "-98.8808"
        },
        "category": [
            "acts",
            "cfc"
        ],
        "skills": [
            {
                "sport": "Climbing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "342fd091-85a3-498a-bdec-1b923ac618ec",
        "type": "group",
        "name": "SFC Slovenia",
        "url": "https://sfcusa.org/",
        "location": {
            "lat": "46.151241",
            "lng": "14.995463"
        },
        "category": [
            "acts",
            "sfc"
        ],
        "skills": [
            {
                "sport": "Snowboarding",
                "level": "all"
            },
            {
                "sport": "Skiing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "24e6ff82-f31f-4a3b-ad01-6abd00a1b975",
        "type": "group",
        "name": "YWAM Freesports",
        "url": "http://www.ywamfreesports.com/",
        "location": {
            "lat": "33.66946",
            "lng": "-117.82311"
        },
        "category": [
            "acts",
            "other"
        ],
        "skills": [
            {
                "sport": "Snowboarding",
                "level": "all"
            },
            {
                "sport": "Skiing",
                "level": "all"
            },
            {
                "sport": "Wakeboarding",
                "level": "all"
            },
            {
                "sport": "Parkour",
                "level": "all"
            }
        ]
    },
    {
        "_id": "01521670-1dea-4fe5-a9c5-9e43ac0cf069",
        "type": "group",
        "name": "Helmets of Salvation",
        "url": "https://www.facebook.com/johndavidbollen",
        "location": {
            "lat": "-26.20227",
            "lng": "28.04363"
        },
        "category": [
            "acts",
            "other"
        ],
        "skills": [
            {
                "sport": "Surfing",
                "level": "all"
            },
            {
                "sport": "Paragliding",
                "level": "all"
            },
            {
                "sport": "Kayaking",
                "level": "all"
            }
        ]
    },
    {
        "_id": "c276f4ea-c31b-4a3c-b41e-4231dfa5d2d3",
        "type": "group",
        "name": "na",
        "url": "http://www.th-e.ch/",
        "location": {
            "lat": "46.94809",
            "lng": "7.44744"
        },
        "category": [
            "acts",
            "other"
        ],
        "skills": [
            {
                "sport": "Paragliding",
                "level": "all"
            }
        ]
    },
    {
        "_id": "3f9e2a96-88b7-485a-bcd4-ab2555d2da99",
        "type": "group",
        "name": "Para|Dise",
        "url": "https://en.para-dise.org/",
        "location": {
            "lat": "46.6086",
            "lng": "13.8506"
        },
        "category": [
            "acts",
            "other"
        ],
        "skills": [
            {
                "sport": "Paragliding",
                "level": "all"
            }
        ]
    },
    {
        "_id": "f4c464f2-7c7a-48a5-90e6-cd976de925c3",
        "type": "group",
        "name": "OM Ships",
        "url": "https://www.facebook.com/samuel.haab",
        "location": {
            "lat": "39.87556",
            "lng": "20.00528"
        },
        "category": [
            "acts",
            "other"
        ],
        "skills": [
            {
                "sport": "Climbing",
                "level": "all"
            },
            {
                "sport": "Skiing",
                "level": "all"
            },
            {
                "sport": "Paragliding",
                "level": "all"
            }
        ]
    },
    {
        "_id": "2e6cd32c-f992-49ac-9f92-7d5cfc25fb63",
        "type": "group",
        "name": "Revolution Skate Ministry / Christian Skaters Costa Rica",
        "url": "https://www.facebook.com/Christian-Skaters-Costa-Rica-603574899653119/",
        "location": {
            "lat": "9.93333",
            "lng": "-84.08333"
        },
        "category": [
            "acts",
            "other"
        ],
        "skills": [
            {
                "sport": "Skateboarding",
                "level": "all"
            }
        ]
    },
    {
        "_id": "2d4363b5-615a-4dc7-adb9-ed99c2eba6e1",
        "type": "group",
        "name": "Christian Surfers International",
        "url": "https://www.christiansurfers.net/",
        "location": {
            "lat": "-33.91799",
            "lng": "25.57007"
        },
        "category": [
            "acts",
            "other"
        ],
        "skills": [
            {
                "sport": "Surfing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "f4bf16a3-ad2e-4e47-8258-82c27ae032cb",
        "type": "group",
        "name": "OM SportsLink Extreme",
        "url": "https://www.omusa.org/",
        "location": {
            "lat": "54.8951",
            "lng": "-2.9382"
        },
        "category": [
            "acts",
            "other"
        ],
        "skills": [
            {
                "sport": "Paragliding",
                "level": "all"
            }
        ]
    },
    {
        "_id": "e687f8b9-d7a1-4783-a2d8-22c99b6712b2",
        "type": "group",
        "name": "OM SportsLink Extreme",
        "url": "https://www.omusa.org/",
        "location": {
            "lat": "43.25",
            "lng": "76.95"
        },
        "category": [
            "acts",
            "other"
        ],
        "skills": [
            {
                "sport": "Snowboarding",
                "level": "all"
            },
            {
                "sport": "Wakeboarding",
                "level": "all"
            }
        ]
    },
    {
        "_id": "8cd85ce2-fd6b-49ab-bfb9-59691443c706",
        "type": "group",
        "name": "Foundation 725",
        "url": "https://www.facebook.com/pages/category/Organization/Foundation-725-139636357401/",
        "location": {
            "lat": "45.8848",
            "lng": "95.3777"
        },
        "category": [
            "acts",
            "other"
        ],
        "skills": [
            {
                "sport": "Snowboarding",
                "level": "all"
            },
            {
                "sport": "Wakeboarding",
                "level": "all"
            }
        ]
    },
    {
        "_id": "6ef0ebd3-c558-40a5-8e2a-83ae7764f54b",
        "type": "group",
        "name": "FCA Surf",
        "url": "http://www.fcasurf.org/",
        "location": {
            "lat": "39.48623",
            "lng": "-75.02573"
        },
        "category": [
            "acts",
            "other"
        ],
        "skills": [
            {
                "sport": "Surfing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "93d9d61f-3365-4174-b4f0-ead2c77de808",
        "type": "group",
        "name": "OM SportsLink Extreme",
        "url": "https://www.omusa.org/",
        "location": {
            "lat": "-31.95224",
            "lng": "115.8614"
        },
        "category": [
            "acts",
            "other"
        ],
        "skills": [
            {
                "sport": "Paragliding",
                "level": "all"
            }
        ]
    },
    {
        "_id": "c71bd0db-8cd9-4c16-9d1c-328aab24f40a",
        "type": "group",
        "name": "Surf Church",
        "url": "https://surfchurch.pt/en/",
        "location": {
            "lat": "45.52345",
            "lng": "-122.67621"
        },
        "category": [
            "acts",
            "other"
        ],
        "skills": [
            {
                "sport": "Surfing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "e6b1bbf7-f9fd-45ec-9ede-546c63a97369",
        "type": "group",
        "name": "Infinite e.V.",
        "url": "https://www.infinite-camp.com",
        "location": {
            "lat": "48.13743",
            "lng": "11.57549"
        },
        "category": [
            "acts",
            "other"
        ],
        "skills": [
            {
                "sport": "Wakeboarding",
                "level": "all"
            }
        ]
    },
    {
        "_id": "744c5524-d907-41dd-93df-ed6cf26cfae6",
        "type": "group",
        "name": "YWAM Freesports, Inspire Europe",
        "url": "http://www.ywamfreesports.com/",
        "location": {
            "lat": "44.40632",
            "lng": "8.93386"
        },
        "category": [
            "acts",
            "other"
        ],
        "skills": [
            {
                "sport": "Kayaking",
                "level": "all"
            }
        ]
    },
    {
        "_id": "2cfe9cbe-42bc-4709-bb18-e247719b1ab1",
        "type": "group",
        "name": "Christian surfers and Christian skateboarders",
        "url": "http://www.christiansurfers.co.uk/",
        "location": {
            "lat": "52.48142",
            "lng": "-1.89983"
        },
        "category": [
            "acts",
            "other"
        ],
        "skills": [
            {
                "sport": "Skateboarding",
                "level": "all"
            },
            {
                "sport": "Surfing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "6482cb74-d485-461f-840b-94eaff7dd714",
        "type": "group",
        "name": "Christian Skaters International",
        "url": "https://www.christianskaters.com/",
        "location": {
            "lat": "27.44671",
            "lng": "-80.32561"
        },
        "category": [
            "acts",
            "other"
        ],
        "skills": [
            {
                "sport": "Skateboarding",
                "level": "all"
            }
        ]
    },
    {
        "_id": "e8724336-4600-4183-b963-19e825a5155b",
        "type": "group",
        "name": "MS Skate Ministry",
        "url": "http://msskateministry.com/",
        "location": {
            "lat": "34.22573",
            "lng": "-77.94471"
        },
        "category": [
            "acts",
            "other"
        ],
        "skills": [
            {
                "sport": "Skateboarding",
                "level": "all"
            }
        ]
    },
    {
        "_id": "4719a276-4aa5-4d56-83d3-914f0b7bd1b4",
        "type": "group",
        "name": "Christian Surfers USA",
        "url": "https://christiansurfers.com/",
        "location": {
            "lat": "-33.91799",
            "lng": "25.57007"
        },
        "category": [
            "acts",
            "other"
        ],
        "skills": [
            {
                "sport": "Surfing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "ee68ac1d-7c94-45c2-9b0b-4d06baa79aa4",
        "type": "group",
        "name": "Surfers Discipleship Training School",
        "url": "https://www.ywamperth.org.au/",
        "location": {
            "lat": "-31.95224",
            "lng": "115.8614"
        },
        "category": [
            "acts",
            "other"
        ],
        "skills": [
            {
                "sport": "Surfing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "05471e8d-c9e1-481c-b528-7bcd060ed9d4",
        "type": "group",
        "name": "Fellowship of Christian Athletes Motocross",
        "url": "http://fcamx.com/",
        "location": {
            "lat": "44.73191",
            "lng": "-93.21772"
        },
        "category": [
            "acts",
            "other"
        ],
        "skills": [
            {
                "sport": "Motocross",
                "level": "all"
            }
        ]
    },
    {
        "_id": "7da99a8c-1470-4fe4-9db8-99df459243fd",
        "type": "group",
        "name": "Megabi skate",
        "url": "https://www.megabiskate.com/",
        "location": {
            "lat": "34.05223",
            "lng": "-118.24368"
        },
        "category": [
            "acts",
            "other"
        ],
        "skills": [
            {
                "sport": "Skateboarding",
                "level": "all"
            }
        ]
    },
    {
        "_id": "d8817d4d-902d-4512-a9a1-b23c0df7fbe2",
        "type": "group",
        "name": "Fellowship of Christian Athletes Motocross",
        "url": "http://fcamx.com/",
        "location": {
            "lat": "11.84962",
            "lng": "-86.19903"
        },
        "category": [
            "acts",
            "other"
        ],
        "skills": [
            {
                "sport": "Motocross",
                "level": "all"
            }
        ]
    },
    {
        "_id": "f3f95480-af45-4530-923e-2d537e1a577f",
        "type": "group",
        "name": "Skate & Vida",
        "url": "https://www.facebook.com/skatevidabahia/",
        "location": {
            "lat": "-12.97111",
            "lng": "-38.51083"
        },
        "category": [
            "acts",
            "other"
        ],
        "skills": [
            {
                "sport": "Skateboarding",
                "level": "all"
            }
        ]
    },
    {
        "_id": "4ccc9c88-c048-48fe-8e2b-08ad3ab4e48e",
        "type": "group",
        "name": "Hope House",
        "url": "http://hopehousemauritius.com/",
        "location": {
            "lat": "-20.21889",
            "lng": "57.49583"
        },
        "category": [
            "acts",
            "other"
        ],
        "skills": [
            {
                "sport": "Surfing",
                "level": "all"
            },
            {
                "sport": "Skim Boarding",
                "level": "all"
            }
        ]
    },
    {
        "_id": "910a0bd4-b4d5-43c8-a56f-03f54bc195dc",
        "type": "group",
        "name": "Outdoor Activities",
        "url": "https://www.highcountrycommunity.net/",
        "location": {
            "lat": "51.52279",
            "lng": "-0.71986"
        },
        "category": [
            "acts",
            "other"
        ],
        "skills": [
            {
                "sport": "Climbing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "df02dd1f-1f3c-4662-b1a6-1d4d562c3abe",
        "type": "group",
        "name": "Gsk8t and Christian skaters Mpumalanga",
        "url": "https://gsk8t.com",
        "location": {
            "lat": "-26.20227",
            "lng": "28.04363"
        },
        "category": [
            "acts",
            "other"
        ],
        "skills": [
            {
                "sport": "Skateboarding",
                "level": "all"
            }
        ]
    },
    {
        "_id": "59f06dee-061b-4f26-9a57-d5af00774ace",
        "type": "group",
        "name": "Bowl Church",
        "url": "https://www.instagram.com/bowlchurch",
        "location": {
            "lat": "43.067",
            "lng": "141.35"
        },
        "category": [
            "acts",
            "other"
        ],
        "skills": [
            {
                "sport": "Skateboarding",
                "level": "all"
            }
        ]
    },
    {
        "_id": "b3d8bc4c-bea0-49ed-8bb3-742f4361022e",
        "type": "group",
        "name": "Christian Skaters",
        "url": "https://www.christianskaters.com",
        "location": {
            "lat": "27.44671",
            "lng": "-80.32561"
        },
        "category": [
            "acts",
            "cskate"
        ],
        "skills": [
            {
                "sport": "Skateboarding",
                "level": "all"
            },
            {
                "sport": "Surfing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "4a30bfc9-440c-4985-a991-25bd45724635",
        "type": "group",
        "name": "Skate Ministry Asia",
        "url": "https://www.facebook.com/skateminasia/",
        "location": {
            "lat": "36.35455",
            "lng": "-119.66656"
        },
        "category": [
            "acts",
            "other"
        ],
        "skills": [
            {
                "sport": "Skateboarding",
                "level": "all"
            }
        ]
    },
    {
        "_id": "c6357e66-5ee4-406b-9f19-a255d4f8d9f2",
        "type": "group",
        "name": "Tai Wan Skate Ministry(TWSM)",
        "url": "https://www.bible.com",
        "location": {
            "lat": "25.04776",
            "lng": "121.53185"
        },
        "category": [
            "acts",
            "other"
        ],
        "skills": [
            {
                "sport": "Skateboarding",
                "level": "all"
            }
        ]
    },
    {
        "_id": "5c601c75-82e0-4d4a-a096-c76652941d83",
        "type": "group",
        "name": "Outdoor Climbers",
        "url": "https://www.bible.com",
        "location": {
            "lat": "28.53834",
            "lng": "-81.37924"
        },
        "category": [
            "acts",
            "other"
        ],
        "skills": [
            {
                "sport": "Climbing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "76444bef-4b27-45e2-9c9d-0e7360daaaee",
        "type": "group",
        "name": "Refuge Skate Ministry",
        "url": "http://coloradorefuge.com/",
        "location": {
            "lat": "40.391537",
            "lng": "-104.681168"
        },
        "category": [
            "acts",
            "other"
        ],
        "skills": [
            {
                "sport": "Skateboarding",
                "level": "all"
            },
            {
                "sport": "BMX",
                "level": "all"
            },
            {
                "sport": "Mountain Biking",
                "level": "all"
            },
            {
                "sport": "Snowboarding",
                "level": "all"
            },
            {
                "sport": "Skiing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "93eeaf41-e3ad-4f58-91d6-9d66138fc229",
        "type": "group",
        "name": "Christian Skaters Eswatini",
        "url": "https://www.facebook.com/cseswatini/",
        "location": {
            "lat": "26.3054",
            "lng": "31.1367"
        },
        "category": [
            "acts",
            "cskate"
        ],
        "skills": [
            {
                "sport": "Skateboarding",
                "level": "all"
            }
        ]
    },
    {
        "_id": "cb358438-3797-4dce-bdf4-5e468f4e7fdc",
        "type": "group",
        "name": "Ang Balay Sa Ride Nature",
        "url": "https://www.facebook.com/angbalaysaridenature",
        "location": {
            "lat": "7.1907",
            "lng": "125.4553"
        },
        "category": [
            "acts",
            "other"
        ],
        "skills": [
            {
                "sport": "Skateboarding",
                "level": "all"
            }
        ]
    },
    {
        "_id": "0c689123-d844-4088-94d5-f09474536be6",
        "type": "group",
        "name": "Cruz Calle Sv",
        "url": "https://www.facebook.com/cruzcallesv",
        "location": {
            "lat": "13.71",
            "lng": "89.1373"
        },
        "category": [
            "acts",
            "other"
        ],
        "skills": [
            {
                "sport": "Skateboarding",
                "level": "all"
            }
        ]
    },
    {
        "_id": "1c59eb79-95e4-45ed-aa1a-9ca27f327fd0",
        "type": "group",
        "name": "Christian Skaters El Salvador",
        "url": "https://www.instagram.com/christianskaterssv/",
        "location": {
            "lat": "13.6929",
            "lng": "89.2182"
        },
        "category": [
            "acts",
            "cskate"
        ],
        "skills": [
            {
                "sport": "Skateboarding",
                "level": "all"
            }
        ]
    },
    {
        "_id": "3d20b3f4-c1db-4997-9282-a071fef5424c",
        "type": "group",
        "name": "Climbers for Christ: Gunks Chapter",
        "url": "https://www.climbersforchrist.org/",
        "location": {
            "lat": "42.085651",
            "lng": "-76.04921"
        },
        "category": [
            "cfc"
        ],
        "skills": [
            {
                "sport": "Climbing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "316857d0-d027-4d96-bdd3-e5e4f55b6882",
        "type": "group",
        "name": "Climbers for Christ: Alleghenny Chapter",
        "url": "https://www.climbersforchrist.org/",
        "location": {
            "lat": "39.65881",
            "lng": "-79.64085"
        },
        "category": [
            "cfc"
        ],
        "skills": [
            {
                "sport": "Climbing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "bdde0346-3c23-4817-af70-9dd8cb86b2c2",
        "type": "group",
        "name": "Climbers for Christ: Lynchburg Chapter",
        "url": "https://www.climbersforchrist.org/",
        "location": {
            "lat": "37.41413",
            "lng": "-81.40828"
        },
        "category": [
            "cfc"
        ],
        "skills": [
            {
                "sport": "Climbing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "92c0f0c0-f994-47d0-9a23-79afb443d1a8",
        "type": "group",
        "name": "Climbers for Christ: Ohio Chapter",
        "url": "https://www.climbersforchrist.org/",
        "location": {
            "lat": "39.889839",
            "lng": "-83.44062"
        },
        "category": [
            "cfc"
        ],
        "skills": [
            {
                "sport": "Climbing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "87652aea-a836-4446-b380-081dd92f32f5",
        "type": "group",
        "name": "Climbers for Christ: Red River Gorge Chapter",
        "url": "https://www.climbersforchrist.org/",
        "location": {
            "lat": "39.889839",
            "lng": "-83.44062"
        },
        "category": [
            "cfc"
        ],
        "skills": [
            {
                "sport": "Climbing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "5af311de-0f8e-451e-aaa5-451c88724905",
        "type": "group",
        "name": "Climbers for Christ: Alabama-East Tennessee Chapter",
        "url": "https://www.climbersforchrist.org/",
        "location": {
            "lat": "35.045631",
            "lng": "-85.309677"
        },
        "category": [
            "cfc"
        ],
        "skills": [
            {
                "sport": "Climbing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "1019deac-e767-46ad-9991-3a033daab8ab",
        "type": "group",
        "name": "Climbers for Christ: Iowa Chapter",
        "url": "https://www.climbersforchrist.org/",
        "location": {
            "lat": "41.977879",
            "lng": "-91.665627"
        },
        "category": [
            "cfc"
        ],
        "skills": [
            {
                "sport": "Climbing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "7dae63a5-6ea4-40f1-99e4-71e964aeb611",
        "type": "group",
        "name": "Climbers for Christ: Mid-Missouri Chapter",
        "url": "https://www.climbersforchrist.org/",
        "location": {
            "lat": "38.951706",
            "lng": "-92.334068"
        },
        "category": [
            "cfc"
        ],
        "skills": [
            {
                "sport": "Climbing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "aec6cd89-7a0c-4694-976f-4d2e38691a07",
        "type": "group",
        "name": "Climbers for Christ: Louisiana Chapter",
        "url": "https://www.climbersforchrist.org/",
        "location": {
            "lat": "30.451468",
            "lng": "-91.187149"
        },
        "category": [
            "cfc"
        ],
        "skills": [
            {
                "sport": "Climbing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "2e0ccc62-cde6-43eb-8057-3401b7ffa31a",
        "type": "group",
        "name": "Climbers for Christ: Texoma Chapter",
        "url": "https://www.climbersforchrist.org/",
        "location": {
            "lat": "33.21484",
            "lng": "-97.133064"
        },
        "category": [
            "cfc"
        ],
        "skills": [
            {
                "sport": "Climbing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "fa6e0820-277e-43b8-ac28-19c8359fb009",
        "type": "group",
        "name": "Climbers for Christ: Central Texas Chapter",
        "url": "https://www.climbersforchrist.org/",
        "location": {
            "lat": "30.505198",
            "lng": "-97.82029"
        },
        "category": [
            "cfc"
        ],
        "skills": [
            {
                "sport": "Climbing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "5d93be8b-d1fc-49cd-ab5b-1453af204a9c",
        "type": "group",
        "name": "Climbers for Christ: San Antonio Chapter",
        "url": "https://www.climbersforchrist.org/",
        "location": {
            "lat": "29.424122",
            "lng": "-98.493629"
        },
        "category": [
            "cfc"
        ],
        "skills": [
            {
                "sport": "Climbing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "32c71652-5d93-441b-a0bc-4592d3934f9b",
        "type": "group",
        "name": "Climbers for Christ: South Dakota Chapter",
        "url": "https://www.climbersforchrist.org/",
        "location": {
            "lat": "44.082989",
            "lng": "-103.224457"
        },
        "category": [
            "cfc"
        ],
        "skills": [
            {
                "sport": "Climbing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "3700a90c-df1b-428f-b01b-f6a31c9463b1",
        "type": "group",
        "name": "Climbers for Christ: Golden Chapter",
        "url": "https://www.climbersforchrist.org/",
        "location": {
            "lat": "39.749668",
            "lng": "-105.216019"
        },
        "category": [
            "cfc"
        ],
        "skills": [
            {
                "sport": "Climbing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "024fef5f-8b74-493a-bfb7-ccf42b1cf318",
        "type": "group",
        "name": "Climbers for Christ: Wyoming Chapter",
        "url": "https://www.climbersforchrist.org/",
        "location": {
            "lat": "42.833981",
            "lng": "-108.735252"
        },
        "category": [
            "cfc"
        ],
        "skills": [
            {
                "sport": "Climbing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "2928a932-1f00-4957-a919-0d19972e0cee",
        "type": "group",
        "name": "Climbers for Christ: Wasatch Chapter",
        "url": "https://www.climbersforchrist.org/",
        "location": {
            "lat": "40.76078",
            "lng": "-111.891045"
        },
        "category": [
            "cfc"
        ],
        "skills": [
            {
                "sport": "Climbing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "698dac9a-a619-415e-aa2f-278913c3336f",
        "type": "group",
        "name": "Climbers for Christ: Las Vegas Chapter",
        "url": "https://www.climbersforchrist.org/",
        "location": {
            "lat": "36.169941",
            "lng": "-115.139832"
        },
        "category": [
            "cfc"
        ],
        "skills": [
            {
                "sport": "Climbing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "0f457ea1-01f1-441a-a464-aa251bd8e374",
        "type": "group",
        "name": "Climbers for Christ: NW Washington Chapter",
        "url": "https://www.climbersforchrist.org/",
        "location": {
            "lat": "47.252876",
            "lng": "-122.44429"
        },
        "category": [
            "cfc"
        ],
        "skills": [
            {
                "sport": "Climbing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "a1980ced-73dc-4bbb-a9d6-b58ddf464a05",
        "type": "group",
        "name": "Climbers for Christ: SE Washington Chapter",
        "url": "https://www.climbersforchrist.org/",
        "location": {
            "lat": "46.241539",
            "lng": "-119.117393"
        },
        "category": [
            "cfc"
        ],
        "skills": [
            {
                "sport": "Climbing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "6ce69c3f-e676-4ba8-ad92-04f846363bf0",
        "type": "group",
        "name": "Climbers for Christ: Lassen Chapter",
        "url": "https://www.climbersforchrist.org/",
        "location": {
            "lat": "40.13974",
            "lng": "-120.95092"
        },
        "category": [
            "cfc"
        ],
        "skills": [
            {
                "sport": "Climbing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "691e073c-999d-4b1d-94aa-fbe1bd1a90ef",
        "type": "group",
        "name": "Climbers for Christ: Bay Area Chapter",
        "url": "https://www.climbersforchrist.org/",
        "location": {
            "lat": "37.966259",
            "lng": "-122.30941"
        },
        "category": [
            "cfc"
        ],
        "skills": [
            {
                "sport": "Climbing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "664b4b03-69d1-4348-9918-f3e461ca2984",
        "type": "group",
        "name": "Climbers for Christ: Los Angeles Chapter",
        "url": "https://www.climbersforchrist.org/",
        "location": {
            "lat": "34.052235",
            "lng": "-118.243683"
        },
        "category": [
            "cfc"
        ],
        "skills": [
            {
                "sport": "Climbing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "9735abf0-e282-4ca3-ae31-9cbbb2b0b311",
        "type": "group",
        "name": "Climbers for Christ: British Columbia Chapter",
        "url": "https://www.facebook.com/groups/climbersforchrist/",
        "location": {
            "lat": "49.287689",
            "lng": "-123.1379362"
        },
        "category": [
            "cfc"
        ],
        "skills": [
            {
                "sport": "Climbing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "6d3cc667-c2e3-44c4-a083-a3a88efa6678",
        "type": "group",
        "name": "Climbers for Christ: Cuba Chapter",
        "url": "https://www.climbersforchrist.org/",
        "location": {
            "lat": "21.521757",
            "lng": "-77.781166"
        },
        "category": [
            "cfc"
        ],
        "skills": [
            {
                "sport": "Climbing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "6cc927ba-02e7-4e34-9aec-8c67dc502195",
        "type": "group",
        "name": "Climbers for Christ: Peru Chapter",
        "url": "https://www.climbersforchrist.org/",
        "location": {
            "lat": "-9.189967",
            "lng": "-75.015152"
        },
        "category": [
            "cfc"
        ],
        "skills": [
            {
                "sport": "Climbing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "a2bb256d-60f0-48fe-8245-b3fff81fd937",
        "type": "group",
        "name": "Climbers for Christ: Argentina Chapter",
        "url": "https://www.climbersforchrist.org/",
        "location": {
            "lat": "-38.416096",
            "lng": "-63.616673"
        },
        "category": [
            "cfc"
        ],
        "skills": [
            {
                "sport": "Climbing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "f9f3a61a-e6f9-4a4c-a4ca-9d478daf8c8f",
        "type": "group",
        "name": "SFC Snowshoe",
        "url": "https://sfcusa.org/",
        "location": {
            "lat": "28.628547",
            "lng": "-81.449738"
        },
        "category": [
            "sfc"
        ],
        "skills": [
            {
                "sport": "Snowboarding",
                "level": "all"
            },
            {
                "sport": "Skiing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "d343af2a-50b6-42f8-a3e9-8045b3117cfd",
        "type": "group",
        "name": "SFC Cannonsburg",
        "url": "https://sfcusa.org/",
        "location": {
            "lat": "38.3888",
            "lng": "-82.70272"
        },
        "category": [
            "sfc"
        ],
        "skills": [
            {
                "sport": "Snowboarding",
                "level": "all"
            },
            {
                "sport": "Skiing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "b4a16d7a-1e70-4120-9332-2989fdace1c9",
        "type": "group",
        "name": "SFC Marquette",
        "url": "https://sfcusa.org/",
        "location": {
            "lat": "46.54391",
            "lng": "-87.39755"
        },
        "category": [
            "sfc"
        ],
        "skills": [
            {
                "sport": "Snowboarding",
                "level": "all"
            },
            {
                "sport": "Skiing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "b4488a6f-4578-4cfd-a621-7e00d740f433",
        "type": "group",
        "name": "SFC Grand Haven",
        "url": "https://sfcusa.org/",
        "location": {
            "lat": "43.0588",
            "lng": "-86.227989"
        },
        "category": [
            "sfc"
        ],
        "skills": [
            {
                "sport": "Snowboarding",
                "level": "all"
            },
            {
                "sport": "Skiing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "214b08ce-d479-4ba6-a629-13bdbd0a15a3",
        "type": "group",
        "name": "SFC Minnesota",
        "url": "https://sfcusa.org/",
        "location": {
            "lat": "46.729553",
            "lng": "-94.685898"
        },
        "category": [
            "sfc"
        ],
        "skills": [
            {
                "sport": "Snowboarding",
                "level": "all"
            },
            {
                "sport": "Skiing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "94faba69-c138-4ece-8d6d-9bbb637ecac9",
        "type": "group",
        "name": "SFC Michigan",
        "url": "https://sfcusa.org/",
        "location": {
            "lat": "44.314842",
            "lng": "-85.602364"
        },
        "category": [
            "sfc"
        ],
        "skills": [
            {
                "sport": "Snowboarding",
                "level": "all"
            },
            {
                "sport": "Skiing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "e923eea8-655a-4076-9468-975fcc6ab3f4",
        "type": "group",
        "name": "SFC Northern Vermont",
        "url": "https://sfcusa.org/",
        "location": {
            "lat": "39.80511",
            "lng": "-87.67144"
        },
        "category": [
            "sfc"
        ],
        "skills": [
            {
                "sport": "Snowboarding",
                "level": "all"
            },
            {
                "sport": "Skiing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "e40f6657-1a8e-46dd-94fd-68385c04e0ea",
        "type": "group",
        "name": "SFC Adirondacks",
        "url": "https://sfcusa.org/",
        "location": {
            "lat": "45.59967",
            "lng": "-73.822952"
        },
        "category": [
            "sfc"
        ],
        "skills": [
            {
                "sport": "Snowboarding",
                "level": "all"
            },
            {
                "sport": "Skiing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "06fbbbb7-131e-4bf7-93c5-db3ecdc1ede1",
        "type": "group",
        "name": "SFC Snoqualmie Pass",
        "url": "https://sfcusa.org/",
        "location": {
            "lat": "47.413651",
            "lng": "-121.413391"
        },
        "category": [
            "sfc"
        ],
        "skills": [
            {
                "sport": "Snowboarding",
                "level": "all"
            },
            {
                "sport": "Skiing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "cb7ccae4-fa8d-418c-a783-7e9f85ad7135",
        "type": "group",
        "name": "SFC Sun Valley",
        "url": "https://sfcusa.org/",
        "location": {
            "lat": "39.596371",
            "lng": "-119.777603"
        },
        "category": [
            "sfc"
        ],
        "skills": [
            {
                "sport": "Snowboarding",
                "level": "all"
            },
            {
                "sport": "Skiing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "327b7aa2-c26a-4aae-999c-30fdc3949a52",
        "type": "group",
        "name": "SFC Spokane",
        "url": "https://sfcusa.org/",
        "location": {
            "lat": "47.658779",
            "lng": "-117.426048"
        },
        "category": [
            "sfc"
        ],
        "skills": [
            {
                "sport": "Snowboarding",
                "level": "all"
            },
            {
                "sport": "Skiing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "245dd0bf-6631-4345-a7d9-42dae5ce3a19",
        "type": "group",
        "name": "SFC Big bear",
        "url": "https://sfcusa.org/",
        "location": {
            "lat": "34.266369",
            "lng": "-116.846771"
        },
        "category": [
            "sfc"
        ],
        "skills": [
            {
                "sport": "Snowboarding",
                "level": "all"
            },
            {
                "sport": "Skiing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "70b8df9a-7698-4414-8a53-ae5bb4d04c4d",
        "type": "group",
        "name": "SFC Tahoe",
        "url": "https://sfcusa.org/",
        "location": {
            "lat": "39.18782",
            "lng": "-120.20413"
        },
        "category": [
            "sfc"
        ],
        "skills": [
            {
                "sport": "Snowboarding",
                "level": "all"
            },
            {
                "sport": "Skiing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "1d348798-0877-49f8-94f3-7ccb2d5cdf36",
        "type": "group",
        "name": "SFC Utah",
        "url": "https://sfcusa.org/",
        "location": {
            "lat": "39.32098",
            "lng": "-111.093735"
        },
        "category": [
            "sfc"
        ],
        "skills": [
            {
                "sport": "Snowboarding",
                "level": "all"
            },
            {
                "sport": "Skiing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "d3fc4854-4b5f-4dce-95c5-7790ea596f48",
        "type": "group",
        "name": "SFC Switezrland",
        "url": "https://sfcusa.org/",
        "location": {
            "lat": "46.948",
            "lng": "7.44813"
        },
        "category": [
            "sfc"
        ],
        "skills": [
            {
                "sport": "Snowboarding",
                "level": "all"
            },
            {
                "sport": "Skiing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "5a9ccd46-8a3d-4744-987b-d2006ff42dc0",
        "type": "group",
        "name": "SFC AUSTRALIA - Jindabyne",
        "url": "https://sfcusa.org/",
        "location": {
            "lat": "-36.41502",
            "lng": "148.61112"
        },
        "category": [
            "sfc"
        ],
        "skills": [
            {
                "sport": "Snowboarding",
                "level": "all"
            },
            {
                "sport": "Skiing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "880db034-aa2b-4857-b746-1a31e7527d48",
        "type": "group",
        "name": "SFC Chile",
        "url": "https://sfcusa.org/",
        "location": {
            "lat": "-35.675148",
            "lng": "-71.542969"
        },
        "category": [
            "sfc"
        ],
        "skills": [
            {
                "sport": "Snowboarding",
                "level": "all"
            },
            {
                "sport": "Skiing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "498a8253-b747-4829-86b5-5e45f10e7701",
        "type": "group",
        "name": "SFC Timberline/Winter Park",
        "url": "https://sfcusa.org/",
        "location": {
            "lat": "28.55319",
            "lng": "-81.30539"
        },
        "category": [
            "sfc"
        ],
        "skills": [
            {
                "sport": "Snowboarding",
                "level": "all"
            },
            {
                "sport": "Skiing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "0468f5ed-dbbf-4532-b92d-9670cb49f026",
        "type": "group",
        "name": "SFC SW-Penn",
        "url": "https://sfcusa.org/",
        "location": {
            "lat": "45.44079",
            "lng": "-122.79616"
        },
        "category": [
            "sfc"
        ],
        "skills": [
            {
                "sport": "Snowboarding",
                "level": "all"
            },
            {
                "sport": "Skiing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "f8361f6d-4103-48ea-9f73-cbd751dfb9ee",
        "type": "group",
        "name": "SFC Liberty U Snowflex",
        "url": "https://sfcusa.org/",
        "location": {
            "lat": "49.61",
            "lng": "6.12963"
        },
        "category": [
            "sfc"
        ],
        "skills": [
            {
                "sport": "Snowboarding",
                "level": "all"
            },
            {
                "sport": "Skiing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "dfad441d-1c3f-4a1c-bc7d-792b0d338770",
        "type": "group",
        "name": "SFC Maine",
        "url": "https://sfcusa.org/",
        "location": {
            "lat": "45.02742",
            "lng": "-69.69054"
        },
        "category": [
            "sfc"
        ],
        "skills": [
            {
                "sport": "Snowboarding",
                "level": "all"
            },
            {
                "sport": "Skiing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "f78c2f35-4e33-42e3-a5d1-6aca6ba2a36a",
        "type": "group",
        "name": "SFC Vail Valley",
        "url": "https://sfcusa.org/",
        "location": {
            "lat": "39.63978",
            "lng": "-106.36051"
        },
        "category": [
            "sfc"
        ],
        "skills": [
            {
                "sport": "Snowboarding",
                "level": "all"
            },
            {
                "sport": "Skiing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "fe497f58-1929-4c5d-b045-40fca6e8b322",
        "type": "group",
        "name": "SFC Whitefish",
        "url": "https://sfcusa.org/",
        "location": {
            "lat": "48.410648",
            "lng": "-114.338188"
        },
        "category": [
            "sfc"
        ],
        "skills": [
            {
                "sport": "Snowboarding",
                "level": "all"
            },
            {
                "sport": "Skiing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "59f18bb3-18b9-4378-9015-e897f635758f",
        "type": "group",
        "name": "Loon Mountain Ministry",
        "url": "https://sfcusa.org/",
        "location": {
            "lat": "44.080898",
            "lng": "-71.545532"
        },
        "category": [
            "sfc"
        ],
        "skills": [
            {
                "sport": "Snowboarding",
                "level": "all"
            },
            {
                "sport": "Skiing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "27ff77f6-3c79-4ebc-bd93-7fd634e54428",
        "type": "group",
        "name": "SFC Midwest",
        "url": "https://sfcusa.org/",
        "location": {
            "lat": "43.40963",
            "lng": "-106.276428"
        },
        "category": [
            "sfc"
        ],
        "skills": [
            {
                "sport": "Snowboarding",
                "level": "all"
            },
            {
                "sport": "Skiing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "40887ccd-b814-4cbc-87e4-f6db1a5b8595",
        "type": "group",
        "name": "SFC Stuttgart",
        "url": "https://sfcusa.org/",
        "location": {
            "lat": "48.775845",
            "lng": "9.182932"
        },
        "category": [
            "sfc"
        ],
        "skills": [
            {
                "sport": "Snowboarding",
                "level": "all"
            },
            {
                "sport": "Skiing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "a7052724-3b86-4567-98e9-d55a2da6e6ca",
        "type": "group",
        "name": "SFC Kalamazoo Michigan",
        "url": "https://sfcusa.org/",
        "location": {
            "lat": "42.291706",
            "lng": "-85.587227"
        },
        "category": [
            "sfc"
        ],
        "skills": [
            {
                "sport": "Snowboarding",
                "level": "all"
            },
            {
                "sport": "Skiing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "8f39c53e-09b2-41b5-900b-3aa0073efd3d",
        "type": "group",
        "name": "SFC North Vancouver",
        "url": "https://sfcusa.org/",
        "location": {
            "lat": "49.319981",
            "lng": "-123.072411"
        },
        "category": [
            "sfc"
        ],
        "skills": [
            {
                "sport": "Snowboarding",
                "level": "all"
            },
            {
                "sport": "Skiing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "a70ba457-863b-4086-8be4-110fde053a5b",
        "type": "group",
        "name": "SFC France",
        "url": "https://sfcusa.org/",
        "location": {
            "lat": "50.41039",
            "lng": "2.84738"
        },
        "category": [
            "sfc"
        ],
        "skills": [
            {
                "sport": "Snowboarding",
                "level": "all"
            },
            {
                "sport": "Skiing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "8f9acafd-8263-4f0e-8104-af0c06c53f2b",
        "type": "group",
        "name": "SFC JAPAN",
        "url": "https://sfcusa.org/",
        "location": {
            "lat": "36.204823",
            "lng": "138.25293"
        },
        "category": [
            "sfc"
        ],
        "skills": [
            {
                "sport": "Snowboarding",
                "level": "all"
            },
            {
                "sport": "Skiing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "c11e46de-fd0f-4c7e-89cf-2638bf2f81cf",
        "type": "group",
        "name": "SFC Thun",
        "url": "https://sfcusa.org/",
        "location": {
            "lat": "46.752571",
            "lng": "7.62343"
        },
        "category": [
            "sfc"
        ],
        "skills": [
            {
                "sport": "Snowboarding",
                "level": "all"
            },
            {
                "sport": "Skiing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "8a8bfada-119d-45ad-8756-5efe6927e8d4",
        "type": "group",
        "name": "SFC Calgary",
        "url": "https://sfcusa.org/",
        "location": {
            "lat": "51.048615",
            "lng": "-114.070847"
        },
        "category": [
            "sfc"
        ],
        "skills": [
            {
                "sport": "Snowboarding",
                "level": "all"
            },
            {
                "sport": "Skiing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "240b392e-6a6a-486a-b682-dac7ba72bf3e",
        "type": "group",
        "name": "SFC Hemel",
        "url": "https://sfcusa.org/",
        "location": {
            "lat": "40.681431",
            "lng": "30.62232"
        },
        "category": [
            "sfc"
        ],
        "skills": [
            {
                "sport": "Snowboarding",
                "level": "all"
            },
            {
                "sport": "Skiing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "7478311b-9b7e-4e8b-855e-5974d2d162d6",
        "type": "group",
        "name": "SFC UK and Castleford",
        "url": "https://sfcusa.org/",
        "location": {
            "lat": "53.72591",
            "lng": "-1.35614"
        },
        "category": [
            "sfc"
        ],
        "skills": [
            {
                "sport": "Snowboarding",
                "level": "all"
            },
            {
                "sport": "Skiing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "c30933d2-9945-48ab-a1cb-430c90b280ca",
        "type": "group",
        "name": "SFC Milton Keynes, UK",
        "url": "https://sfcusa.org/",
        "location": {
            "lat": "52.041439",
            "lng": "-0.76056"
        },
        "category": [
            "sfc"
        ],
        "skills": [
            {
                "sport": "Snowboarding",
                "level": "all"
            },
            {
                "sport": "Skiing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "cd516e82-e082-47b4-abf4-8c706cfa0631",
        "type": "group",
        "name": "SFC Wanaka",
        "url": "https://sfcusa.org/",
        "location": {
            "lat": "-44.6945",
            "lng": "169.141037"
        },
        "category": [
            "sfc"
        ],
        "skills": [
            {
                "sport": "Snowboarding",
                "level": "all"
            },
            {
                "sport": "Skiing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "c4cf96b1-370e-45de-bd28-dd9b63e31afc",
        "type": "group",
        "name": "SFC Scotland",
        "url": "https://sfcusa.org/",
        "location": {
            "lat": "56.490669",
            "lng": "-4.202646"
        },
        "category": [
            "sfc"
        ],
        "skills": [
            {
                "sport": "Snowboarding",
                "level": "all"
            },
            {
                "sport": "Skiing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "a04f1047-4eb7-4cf5-9372-45e75cc74f30",
        "type": "group",
        "name": "SFC Summit",
        "url": "https://sfcusa.org/summit/",
        "location": {
            "lat": "43.077358",
            "lng": "-88.483391"
        },
        "category": [
            "sfc"
        ],
        "skills": [
            {
                "sport": "Snowboarding",
                "level": "all"
            },
            {
                "sport": "Skiing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "0370ebba-1909-4069-b81c-1112d5edb3ac",
        "type": "group",
        "name": "SFC Bozeman,MT",
        "url": "https://sfcusa.org/",
        "location": {
            "lat": "45.679291",
            "lng": "-111.046608"
        },
        "category": [
            "sfc"
        ],
        "skills": [
            {
                "sport": "Snowboarding",
                "level": "all"
            },
            {
                "sport": "Skiing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "d908adcf-0ee2-4d1b-b01e-07b735a1e244",
        "type": "group",
        "name": "SFC Brainerd, MN",
        "url": "https://sfcusa.org/",
        "location": {
            "lat": "46.35471",
            "lng": "-94.202164"
        },
        "category": [
            "sfc"
        ],
        "skills": [
            {
                "sport": "Snowboarding",
                "level": "all"
            },
            {
                "sport": "Skiing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "53e00a3c-af1e-45da-9522-dde9490d3780",
        "type": "group",
        "name": "Unbound SRSactionsports",
        "url": "https://www.srsonline.de/portfolio-items/climbunbound",
        "location": {
            "lat": "50.110924",
            "lng": "8.682127"
        },
        "category": [
            "unbound"
        ],
        "skills": [
            {
                "sport": "Climbing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "d958240c-836e-4328-bed8-53496c67b0b2",
        "type": "group",
        "name": "Unbound SRSactionsports",
        "url": "https://www.srsonline.de/portfolio-items/skateunbound",
        "location": {
            "lat": "50.832699",
            "lng": "12.92421"
        },
        "category": [
            "unbound"
        ],
        "skills": [
            {
                "sport": "Skateboarding",
                "level": "all"
            }
        ]
    },
    {
        "_id": "fc107954-e5e1-4545-9e95-67db0af8cd9f",
        "type": "group",
        "name": "Unbound SRSactionsports",
        "url": "https://www.srsonline.de/portfolio-items/surfunbound",
        "location": {
            "lat": "47.65197",
            "lng": "9.47849"
        },
        "category": [
            "unbound"
        ],
        "skills": [
            {
                "sport": "Surfing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "21747e01-2f50-4424-b7be-1053d3d7c5bc",
        "type": "group",
        "name": "Unbound SRSactionsports",
        "url": "https://www.srsonline.de/portfolio-items/bikeunbound",
        "location": {
            "lat": "50.832699",
            "lng": "12.92421"
        },
        "category": [
            "unbound"
        ],
        "skills": [
            {
                "sport": "Mountain Biking",
                "level": "all"
            }
        ]
    },
    {
        "_id": "fb03cb67-e8d2-46d0-b2bb-3fa772b734a8",
        "type": "group",
        "name": "Unbound SRSactionsports",
        "url": "https://www.srsonline.de/portfolio-items/snowunbound",
        "location": {
            "lat": "47.723942",
            "lng": "10.31105"
        },
        "category": [
            "unbound"
        ],
        "skills": [
            {
                "sport": "Snowboarding",
                "level": "all"
            }
        ]
    },
    {
        "_id": "1e7fe1bd-4b46-44bc-9477-7a640b5eb916",
        "type": "group",
        "name": "Unbound SRSactionsports",
        "url": "https://www.srsonline.de/portfolio-items/surfunbound",
        "location": {
            "lat": "54.323292",
            "lng": "10.122765"
        },
        "category": [
            "unbound"
        ],
        "skills": [
            {
                "sport": "Kite Boarding",
                "level": "all"
            }
        ]
    },
    {
        "_id": "6faf0719-e271-4ba9-b10a-2bfdca3b851a",
        "type": "group",
        "name": "Christian Surfers Mauritius",
        "url": "https://www.facebook.com/saltsurfersmauritius/",
        "location": {
            "lat": "-20.3545892",
            "lng": "57.3736675"
        },
        "category": [
            "csurf"
        ],
        "skills": [
            {
                "sport": "Surfing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "bae360f1-d17e-4755-b73a-2697b4a16238",
        "type": "group",
        "name": "Christian Surfers South Africa",
        "url": "https://www.christiansurfers.co.za",
        "location": {
            "lat": "-34.0506714",
            "lng": "24.9101544"
        },
        "category": [
            "csurf"
        ],
        "skills": [
            {
                "sport": "Surfing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "83d757f8-a60c-4c3f-a27c-0cd698f7a4c1",
        "type": "group",
        "name": "Christian Surfers France",
        "url": "https://www.christiansurfers.fr",
        "location": {
            "lat": "43.4831519",
            "lng": "-1.558626"
        },
        "category": [
            "csurf"
        ],
        "skills": [
            {
                "sport": "Surfing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "d5656855-a524-421d-adaa-ea6176936866",
        "type": "group",
        "name": "Christian Surfers Germany",
        "url": "https://www.christiansurfers.de",
        "location": {
            "lat": "47.9479298",
            "lng": "11.1032858"
        },
        "category": [
            "csurf"
        ],
        "skills": [
            {
                "sport": "Surfing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "0b6ae743-afca-44ee-8b0c-bf67b4dcd128",
        "type": "group",
        "name": "Christian Surfers Holland",
        "url": "https://www.christiansurfers.nl",
        "location": {
            "lat": "52.19802",
            "lng": "4.4199429"
        },
        "category": [
            "csurf"
        ],
        "skills": [
            {
                "sport": "Surfing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "fffd454f-68fb-41db-8430-32d25fe24560",
        "type": "group",
        "name": "Christian Surfers Norway",
        "url": "https://www.christiansurfersnorway.com",
        "location": {
            "lat": "62.2034454",
            "lng": "5.1708738"
        },
        "category": [
            "csurf"
        ],
        "skills": [
            {
                "sport": "Surfing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "197c504a-35cf-4644-ab8f-c7527211f02a",
        "type": "group",
        "name": "Christian Surfers Portugal",
        "url": "https://www.christiansurfersportugal.com",
        "location": {
            "lat": "38.7222524",
            "lng": "-9.1393366"
        },
        "category": [
            "csurf"
        ],
        "skills": [
            {
                "sport": "Surfing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "77d6b7cf-d134-45ff-a188-3eccb8822288",
        "type": "group",
        "name": "Christian Surfers Spain",
        "url": "https://www.christiansurfers.es",
        "location": {
            "lat": "41.3850639",
            "lng": "2.1734035"
        },
        "category": [
            "csurf"
        ],
        "skills": [
            {
                "sport": "Surfing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "a838b276-a3ef-42ae-9611-d18ca242197a",
        "type": "group",
        "name": "Christian Surfers Sweden",
        "url": "https://www.christiansurfers.se",
        "location": {
            "lat": "63.3496305",
            "lng": "13.4529078"
        },
        "category": [
            "csurf"
        ],
        "skills": [
            {
                "sport": "Surfing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "7539bbd5-5eed-49e3-b1c6-c26bbb650ccc",
        "type": "group",
        "name": "Christian Surfers Switzerland",
        "url": "https://www.christiansurfers.ch",
        "location": {
            "lat": "47.4626895",
            "lng": "7.8361995"
        },
        "category": [
            "csurf"
        ],
        "skills": [
            {
                "sport": "Surfing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "37ed925b-988e-499f-8ad4-7f5145bd3d7a",
        "type": "group",
        "name": "Christian Surfers Denmark",
        "url": "https://www.christiansurfers.dk",
        "location": {
            "lat": "55.669217",
            "lng": "12.402144"
        },
        "category": [
            "csurf"
        ],
        "skills": [
            {
                "sport": "Surfing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "92707984-47eb-405c-a170-fb32ff886979",
        "type": "group",
        "name": "Christian Surfers United Kingdom",
        "url": "https://www.christiansurfers.co.uk",
        "location": {
            "lat": "51.431756",
            "lng": "-2.756287"
        },
        "category": [
            "csurf"
        ],
        "skills": [
            {
                "sport": "Surfing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "f22fb3e0-2a2b-4dd5-be03-18a028352120",
        "type": "group",
        "name": "Christian Surfers United States",
        "url": "https://www.christiansurfers.com",
        "location": {
            "lat": "sh",
            "lng": "-81.3124341"
        },
        "category": [
            "csurf"
        ],
        "skills": [
            {
                "sport": "Surfing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "d0efffed-47a5-456c-824c-c25a050696cd",
        "type": "group",
        "name": "Christian Surfers Canada",
        "url": "https://www.facebook.com/groups/19712988043/",
        "location": {
            "lat": "44.6756164",
            "lng": "-63.3975706"
        },
        "category": [
            "csurf"
        ],
        "skills": [
            {
                "sport": "Surfing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "f57de597-5fd9-4b21-a535-a6281d47e7dd",
        "type": "group",
        "name": "Christian Surfers Barbados",
        "url": "https://www.facebook.com/CSBarbados/",
        "location": {
            "lat": "13.1059816",
            "lng": "-59.6131741"
        },
        "category": [
            "csurf"
        ],
        "skills": [
            {
                "sport": "Surfing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "f7df6709-9e76-4d95-ae5d-2c4e443bd655",
        "type": "group",
        "name": "Christian Surfers Costa Rica",
        "url": "https://www.facebook.com/ChristianSurfersCostaRica/",
        "location": {
            "lat": "9.9778439",
            "lng": "-84.8294211"
        },
        "category": [
            "csurf"
        ],
        "skills": [
            {
                "sport": "Surfing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "024ae578-49cb-4e4f-add0-0f3a15020131",
        "type": "group",
        "name": "Christian Surfers Panama",
        "url": "https://www.facebook.com/cspanama/",
        "location": {
            "lat": "8.9823792",
            "lng": "-79.5198696"
        },
        "category": [
            "csurf"
        ],
        "skills": [
            {
                "sport": "Surfing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "9309a0f9-3179-4e8c-8f43-34d9b7007a05",
        "type": "group",
        "name": "Christian Surfers Venezuela",
        "url": "https://www.facebook.com/SurfistasDeCristoVenezuela/",
        "location": {
            "lat": "10.6135398",
            "lng": "-66.8498883"
        },
        "category": [
            "csurf"
        ],
        "skills": [
            {
                "sport": "Surfing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "27aa9842-1e32-4ea6-b963-0e84fb8e6b24",
        "type": "group",
        "name": "Christian Surfers Cuba",
        "url": "https://www.facebook.com/christiansurfcuba/",
        "location": {
            "lat": "23.1135925",
            "lng": "-82.3665956"
        },
        "category": [
            "csurf"
        ],
        "skills": [
            {
                "sport": "Surfing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "3c5f98c1-4fbc-4c55-915b-8252a43cafdb",
        "type": "group",
        "name": "Christian Surfers Dominican Republic",
        "url": "https://www.facebook.com/SurfistasCristianosRD",
        "location": {
            "lat": "18.5600761",
            "lng": "-68.372535"
        },
        "category": [
            "csurf"
        ],
        "skills": [
            {
                "sport": "Surfing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "7f1ed6c6-0082-4f4e-aef1-ec50f59ff381",
        "type": "group",
        "name": "Christian Surfers Mexico",
        "url": "https://www.facebook.com/SurfistasCristianosMexico/",
        "location": {
            "lat": "15.8719795",
            "lng": "-97.0767365"
        },
        "category": [
            "csurf"
        ],
        "skills": [
            {
                "sport": "Surfing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "16ab6029-0f77-4d03-b45c-50af1ec037ff",
        "type": "group",
        "name": "Christian Surfers Guatemala",
        "url": "https://www.facebook.com/CSGT502/",
        "location": {
            "lat": "14.6349149",
            "lng": "-90.5068824"
        },
        "category": [
            "csurf"
        ],
        "skills": [
            {
                "sport": "Surfing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "83eaad81-8987-4f49-bf37-7f3dbb44afb8",
        "type": "group",
        "name": "Christian Surfers El Salvador",
        "url": "https://www.facebook.com/ChristianSurfersElSalvador",
        "location": {
            "lat": "13.4945055",
            "lng": "-89.3837285"
        },
        "category": [
            "csurf"
        ],
        "skills": [
            {
                "sport": "Surfing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "c593671c-76c6-420e-96b0-8228401b5c49",
        "type": "group",
        "name": "Christian Surfers Tahiti",
        "url": "https://www.facebook.com/Christian-Surfers-Tahiti-402648783518076/",
        "location": {
            "lat": "-17.5601684",
            "lng": "-149.6079455"
        },
        "category": [
            "csurf"
        ],
        "skills": [
            {
                "sport": "Surfing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "116789e6-f9f5-4344-b89a-f73a5a897849",
        "type": "group",
        "name": "Christian Surfers New Zealand",
        "url": "https://www.christiansurfers.org.nz",
        "location": {
            "lat": "-39.0556253",
            "lng": "174.0752278"
        },
        "category": [
            "csurf"
        ],
        "skills": [
            {
                "sport": "Surfing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "ec496284-b6e7-47aa-830c-4819f417e44c",
        "type": "group",
        "name": "Christian Surfers Australia",
        "url": "https://www.christiansurfers.org.au",
        "location": {
            "lat": "-33.6531584",
            "lng": "115.3454892"
        },
        "category": [
            "csurf"
        ],
        "skills": [
            {
                "sport": "Surfing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "8f99662b-75b2-403f-bdd3-730d16f78572",
        "type": "group",
        "name": "Christian Surfers Japan",
        "url": "https://www.christiansurfersjapan.net",
        "location": {
            "lat": "35.3354155",
            "lng": "140.1832516"
        },
        "category": [
            "csurf"
        ],
        "skills": [
            {
                "sport": "Surfing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "d8101f8c-f12c-417b-9e93-406e7e7552a4",
        "type": "group",
        "name": "Christian Surfers Taiwan",
        "url": "https://www.christiansurfers.net/",
        "location": {
            "lat": "25.0169826",
            "lng": "121.4627868"
        },
        "category": [
            "csurf"
        ],
        "skills": [
            {
                "sport": "Surfing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "43b8d6ce-d018-4d62-933a-540e5d1443df",
        "type": "group",
        "name": "Christian Surfers Thailand",
        "url": "https://www.facebook.com/ChristianSurfersThailand/",
        "location": {
            "lat": "8.0107418",
            "lng": "98.3437415"
        },
        "category": [
            "csurf"
        ],
        "skills": [
            {
                "sport": "Surfing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "c8b21563-58c0-4f66-82e9-1027edfd7d6f",
        "type": "group",
        "name": "Christian Surfers Vanuatu",
        "url": "https://www.christiansurfers.net/",
        "location": {
            "lat": "-17.7332512",
            "lng": "168.3273245"
        },
        "category": [
            "csurf"
        ],
        "skills": [
            {
                "sport": "Surfing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "3a652849-0e62-4b09-89ec-3268089de48b",
        "type": "group",
        "name": "Christian Surfers Solomon Islands",
        "url": "https://www.christiansurfers.net/",
        "location": {
            "lat": "-8.3256742",
            "lng": "157.2698902"
        },
        "category": [
            "csurf"
        ],
        "skills": [
            {
                "sport": "Surfing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "d0eae062-d6d1-4389-a349-e06d488546bf",
        "type": "group",
        "name": "Christian Surfers Fiji",
        "url": "https://www.christiansurfers.net/",
        "location": {
            "lat": "-18.2476528",
            "lng": "177.8862712"
        },
        "category": [
            "csurf"
        ],
        "skills": [
            {
                "sport": "Surfing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "b2b4485e-c749-4b9a-a15a-dca898139636",
        "type": "group",
        "name": "Christian Surfers Brazil",
        "url": "https://www.surfistasdecristo.com.br",
        "location": {
            "lat": "-23.5505199",
            "lng": "-46.6333094"
        },
        "category": [
            "csurf"
        ],
        "skills": [
            {
                "sport": "Surfing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "424b14dd-9475-4a95-a3e7-ce95671cb7a5",
        "type": "group",
        "name": "Christian Surfers Chile",
        "url": "https://www.facebook.com/ChristianSurfersChile/",
        "location": {
            "lat": "-34.3867245",
            "lng": "-72.0047731"
        },
        "category": [
            "csurf"
        ],
        "skills": [
            {
                "sport": "Surfing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "24260fe4-dc03-455e-9ba2-039d69bd84cd",
        "type": "group",
        "name": "Christian Surfers Ecuador",
        "url": "https://www.christiansurfers.ec",
        "location": {
            "lat": "-2.2233633",
            "lng": "-80.958462"
        },
        "category": [
            "csurf"
        ],
        "skills": [
            {
                "sport": "Surfing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "7e34ff60-a0b3-41e9-a5dd-3208bb45b161",
        "type": "group",
        "name": "Christian Surfers Peru",
        "url": "https://www.facebook.com/christiansurfers.peru",
        "location": {
            "lat": "-12.302735",
            "lng": "-76.7830867"
        },
        "category": [
            "csurf"
        ],
        "skills": [
            {
                "sport": "Surfing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "cbf11394-86c0-41fd-abcc-8c75209df76a",
        "type": "group",
        "name": "Christian Surfers Uruguay",
        "url": "https://www.facebook.com/csuruguay",
        "location": {
            "lat": "-34.9011127",
            "lng": "-56.1645314"
        },
        "category": [
            "csurf"
        ],
        "skills": [
            {
                "sport": "Surfing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "9d4b12d2-1071-4a9f-b537-777a0037968f",
        "type": "group",
        "name": "WakeWell Allen",
        "url": "https://www.wakewell.org/connect/",
        "location": {
            "lat": "33.1031744",
            "lng": "-96.6705503"
        },
        "category": [
            "wakewell"
        ],
        "skills": [
            {
                "sport": "Wakeboarding",
                "level": "all"
            }
        ]
    },
    {
        "_id": "d58f9549-46bf-4d89-a5dc-40ecee39c020",
        "type": "group",
        "name": "WakeWell Little Elm",
        "url": "https://www.wakewell.org/connect/",
        "location": {
            "lat": "33.1626193",
            "lng": "-96.9375051"
        },
        "category": [
            "wakewell"
        ],
        "skills": [
            {
                "sport": "Wakeboarding",
                "level": "all"
            }
        ]
    },
    {
        "_id": "ca52d7f6-b9ad-4747-916c-416939c8b860",
        "type": "group",
        "name": "WakeWell College Station",
        "url": "https://www.wakewell.org/connect/",
        "location": {
            "lat": "30.627977",
            "lng": "-96.3344068"
        },
        "category": [
            "wakewell"
        ],
        "skills": [
            {
                "sport": "Wakeboarding",
                "level": "all"
            }
        ]
    },
    {
        "_id": "0e18b011-3747-40b5-b3b1-7b060913da13",
        "type": "group",
        "name": "WakeWell Lake of the Ozarks",
        "url": "https://www.wakewell.org/connect/",
        "location": {
            "lat": "38.1380003",
            "lng": "-92.8103551"
        },
        "category": [
            "wakewell"
        ],
        "skills": [
            {
                "sport": "Wakeboarding",
                "level": "all"
            }
        ]
    },
    {
        "_id": "42f0fc7c-97aa-4652-b45a-bebb7eb02aa6",
        "type": "group",
        "name": "WakeWell Lake Mohawk",
        "url": "https://www.wakewell.org/connect/",
        "location": {
            "lat": "40.6639007",
            "lng": "-81.1899071"
        },
        "category": [
            "wakewell"
        ],
        "skills": [
            {
                "sport": "Wakeboarding",
                "level": "all"
            }
        ]
    },
    {
        "_id": "a0b8ef83-9a6e-44c3-b510-c7206af3c98c",
        "type": "group",
        "name": "WakeWell Rockford",
        "url": "https://www.wakewell.org/connect/",
        "location": {
            "lat": "42.2711311",
            "lng": "-89.0939952"
        },
        "category": [
            "wakewell"
        ],
        "skills": [
            {
                "sport": "Wakeboarding",
                "level": "all"
            }
        ]
    },
    {
        "_id": "bb62950d-5254-431f-abb3-0f5cf43a8890",
        "type": "group",
        "name": "WakeWell Orlando",
        "url": "https://www.wakewell.org/connect/",
        "location": {
            "lat": "28.5383355",
            "lng": "-81.3792365"
        },
        "category": [
            "wakewell"
        ],
        "skills": [
            {
                "sport": "Wakeboarding",
                "level": "all"
            }
        ]
    },
    {
        "_id": "7fe80b9b-151d-441d-8ac4-aa63227bd5b5",
        "type": "group",
        "name": "WakeWell Cartersville",
        "url": "https://www.wakewell.org/connect/",
        "location": {
            "lat": "34.1650972",
            "lng": "-84.7999382"
        },
        "category": [
            "wakewell"
        ],
        "skills": [
            {
                "sport": "Wakeboarding",
                "level": "all"
            }
        ]
    },
    {
        "_id": "6963cb94-e40b-4948-acff-408afafd6859",
        "type": "group",
        "name": "WakeWell Lake Lanier",
        "url": "https://www.wakewell.org/connect/",
        "location": {
            "lat": "34.2218685",
            "lng": "-83.9691346"
        },
        "category": [
            "wakewell"
        ],
        "skills": [
            {
                "sport": "Wakeboarding",
                "level": "all"
            }
        ]
    },
    {
        "_id": "fdf4edb0-f9d3-4840-a60d-992eca6c3f81",
        "type": "group",
        "name": "WakeWell Oklahoma City",
        "url": "https://www.wakewell.org/connect/",
        "location": {
            "lat": "35.4675602",
            "lng": "-97.5164276"
        },
        "category": [
            "wakewell"
        ],
        "skills": [
            {
                "sport": "Wakeboarding",
                "level": "all"
            }
        ]
    },
    {
        "_id": "4ddcfc02-d1be-4e15-9933-1ae255919184",
        "type": "group",
        "name": "Chick Climber",
        "url": "https://chickclimber.com",
        "location": {
            "lat": "44.0805",
            "lng": "103.231"
        },
        "category": [
            "acts",
            "other"
        ],
        "skills": [
            {
                "sport": "Climbing",
                "level": "all"
            }
        ]
    },
    {
        "_id": "0ae4787f-264c-4c17-9876-b060f1d29b37",
        "type": "group",
        "name": "Christian Skaters Guatemala",
        "url": "https://www.facebook.com/ChristianSkatersGuatemala/?fref=ts",
        "location": {
            "lat": "14.5028",
            "lng": "-90.5517"
        },
        "category": [
            "acts",
            "cskate"
        ],
        "skills": [
            {
                "sport": "Skateboarding",
                "level": "all"
            }
        ]
    },
    {
        "_id": "f5e11810-b416-41bf-a1a8-69c087f9b458",
        "type": "group",
        "name": "Asphalto Sports - New Sports Trends",
        "url": "https://www.facebook.com/groups/137343820029",
        "location": {
            "lat": "4.624335",
            "lng": "-74.063644"
        },
        "category": [
            "acts",
            "other"
        ],
        "skills": [
            {
                "sport": "Skateboarding",
                "level": "all"
            },
            {
                "sport": "Parkour",
                "level": "all"
            }
        ]
    }
]

export default markers;