import {
  call, put, select, takeEvery,
} from 'redux-saga/effects';
import { callApi } from '../../api/api';
import { retry } from '../../utils/sagaHelpers';
import {
  GET_USERS_REQUEST,
  GET_USER_REQUEST,
  SAVE_USER_REQUEST,
  UPDATE_USER_REQUEST,
  UPDATE_USER_PASSWORD_REQUEST,
  GET_NEAR_USERS_REQUEST,
  LINK_TO_FACEBOOK_REQUEST,
} from './constants';
import {
  getUsers,
  getUser,
  saveUser,
  updateUser,
  getNearByUsers,
  updateUserPassword,
  linkToFacebook,
} from './actions';
import { updateFacebookId } from '../session/actions';
import { makeSelectSessionUserData, makeSelectSessionUserId } from '../session/selectors';
import { makeSelectCurrentUser } from './selectors';
import { retrySession } from '../session/sagas';
import { setAuthToken } from '../../utils/localStorageStore';

/**
* Retry for users requests.
*/
export const retryUsers = retry({ delay: 200, attempt: 1 });

/**
* Get Users Flow.
*/
export function* getUsersFlow() {
  try {
    const params = { api: 'USER', token: true };

    const users = yield call(retryUsers, callApi, params);

    yield put(getUsers.success(users));
  } catch (error) {
    yield put(getUsers.failure(error.message));
  }
}

/**
* Get User Flow.
*/
export function* getUserFlow(action) {
  try {
    const params = {
      api: 'USER',
      endpoint: `?identityID=${action.payload}`,
      token: true,
    };

    const data = yield call(retryUsers, callApi, params);

    yield put(getUser.success(data));
  } catch (error) {
    yield put(getUser.failure(error.message));
  }
}

/**
* Save User Flow.
*/
export function* saveUserFlow(action) {
  try {
    const params = {
      method: 'POST',
      api: 'USER',
      body: action.payload,
      token: true,
    };

    const data = yield call(retryUsers, callApi, params);

    yield put(saveUser.success(data));
  } catch (error) {
    yield put(saveUser.failure(error.message));
  }
}

/**
* Update User Flow.
*/
export function* updateUserFlow(action) {
  const { requestData, resolve, reject } = action.payload;
  try {
    const userData = yield select(makeSelectSessionUserData());

    if (userData.get('method') === 'email') {
      const bodyForRecaptcha = {
        'g-recaptcha-response': requestData.get('captcha'),
      };
      const params = {
        method: 'POST',
        api: 'AUTH',
        endpoint: '/recaptcha',
        body: bodyForRecaptcha,
      };

      const captchaResponse = yield call(retrySession, callApi, params);
      if (captchaResponse) {
        yield call(setAuthToken, captchaResponse);

        // Save a new user to API-User
        const data = yield put(saveUser.request({
          identityID: userData.get('_id'),
          publicEmail: userData.get('email'),
          ...requestData.delete('captcha').toJS(),
        }));

        const response = (data && data.payload ? data.payload : data);

        yield put(updateUser.success(response));
        resolve(response);
      }
    } else {
      const currentUser = yield select(makeSelectCurrentUser());
      const body = requestData
        .remove('captcha')
        .remove('facebookID')
        .toJS();

      const params = {
        method: 'PUT',
        api: 'USER',
        endpoint: `/${currentUser.get('_id')}`,
        body,
        token: true,
      };
      const data = yield call(retryUsers, callApi, params);

      yield put(updateUser.success(data));
      resolve(data);
    }
  } catch (error) {
    const m = error.message || 'ServerGeneralError';
    yield put(updateUser.failure(m));
    reject(m);
  }
}

/**
* Update User Flow.
*/
export function* updateUserPasswordFlow(action) {
  const { data, resolve, reject } = action.payload;
  try {
    const params = {
      method: 'POST',
      api: 'AUTH',
      endpoint: '/password/change',
      body: data,
      token: true,
    };

    const respData = yield call(retryUsers, callApi, params);

    yield put(updateUserPassword.success(respData));
    resolve(respData);
  } catch (error) {
    yield put(updateUserPassword.failure(error.message));
    reject(error.message);
  }
}

/**
* Get Users Flow.
*/
export function* getNearByUsersFlow(action) {
  try {
    const params = {
      method: 'POST',
      api: 'USER',
      endpoint: '/nearby',
      body: action.payload,
      token: true,
    };

    const nearByUsers = yield call(retryUsers, callApi, params);

    yield put(getNearByUsers.success(nearByUsers));
  } catch (error) {
    yield put(getNearByUsers.failure(error.message));
  }
}

/**
 * Link to Facebook Flow
 */
export function* linkToFacebookFlow(action) {
  const { data, resolve, reject } = action.payload;
  try {
    const { tokenDetail } = data;
    const body = { facebookID: tokenDetail.userID };

    const identityID = yield select(makeSelectSessionUserId());

    const params = {
      method: 'PUT',
      api: 'AUTH',
      endpoint: `/${identityID}`,
      body,
      token: true,
    };

    yield call(retryUsers, callApi, params);

    yield put(linkToFacebook.success());
    resolve();
    yield put(updateFacebookId(body));
  } catch (error) {
    yield put(linkToFacebook.failure(error.message));
    reject(error.message);
  }
}

/**
* Watch Users saga.
*/
export function* watchUsers() {
  yield takeEvery(GET_USERS_REQUEST, getUsersFlow);
  yield takeEvery(GET_USER_REQUEST, getUserFlow);
  yield takeEvery(SAVE_USER_REQUEST, saveUserFlow);
  yield takeEvery(UPDATE_USER_REQUEST, updateUserFlow);
  yield takeEvery(UPDATE_USER_PASSWORD_REQUEST, updateUserPasswordFlow);
  yield takeEvery(GET_NEAR_USERS_REQUEST, getNearByUsersFlow);
  yield takeEvery(LINK_TO_FACEBOOK_REQUEST, linkToFacebookFlow);
}
