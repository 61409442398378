import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectIsAuthenticated,
  makeSelectAuthenticating,
} from '../../redux/session/selectors';
import PageProgressBar from '../../components/PageProgressBar';

const authWrapper = (Route) => {
  const AuthenticatedComponent = (props) => {
    const {
      authenticated,
      visibleOnlyGuest,
      authenticating,
      isAuthenticated,
    } = props;

    if (authenticating) {
      return (<PageProgressBar isLoading pastDelay />);
    }

    // Redirect to Sign In page if an user is not authenticated
    if (authenticated && !isAuthenticated) {
      return (<Redirect to="/signin" />);
    }

    // Redirect to Home page if an user is authenticated
    if (visibleOnlyGuest && isAuthenticated) {
      return (<Redirect to="/" />);
    }

    return (
      <Route {...props} />
    );
  };

  AuthenticatedComponent.propTypes = {
    authenticated: PropTypes.bool,
    visibleOnlyGuest: PropTypes.bool,
    isAuthenticated: PropTypes.bool,
    authenticating: PropTypes.bool,
  };

  const mapStateToProps = createStructuredSelector({
    isAuthenticated: makeSelectIsAuthenticated(),
    authenticating: makeSelectAuthenticating(),
  });

  return connect(mapStateToProps)(AuthenticatedComponent);
};

export default authWrapper;
