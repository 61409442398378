import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Loadable from 'react-loadable';
import { animateScroll } from 'react-scroll';
import {
  makeSelectPathName,
  makeSelectGreaterThan,
  makeSelectLessThan,
} from '../../redux/app/selectors';
import { makeSelectIsAuthenticated } from '../../redux/session/selectors';
import AsyncRouteComponent from './components/AsyncRouteComponent';
import authWrapper from '../AuthWrapper';
import PageProgressBar from '../../components/PageProgressBar';
import { safePush } from '../../utils';

class AsyncRoute extends Component {
  static propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    container: PropTypes.object.isRequired,
    greaterThan: PropTypes.shape({
      extraSmall: PropTypes.bool.isRequired,
      small: PropTypes.bool.isRequired,
      medium: PropTypes.bool.isRequired,
      large: PropTypes.bool.isRequired,
      infinity: PropTypes.bool.isRequired,
    }).isRequired,
    lessThan: PropTypes.shape({
      extraSmall: PropTypes.bool.isRequired,
      small: PropTypes.bool.isRequired,
      medium: PropTypes.bool.isRequired,
      large: PropTypes.bool.isRequired,
      infinity: PropTypes.bool.isRequired,
    }).isRequired,
    footer: PropTypes.bool,
    bottomNav: PropTypes.bool,
    push: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    const {
      container, lessThan, greaterThan, footer, bottomNav,
    } = this.props;

    this.component = Loadable({
      loader: () => container,
      loading: (loadingProps) => {
        return (
          <PageProgressBar
            footer={greaterThan.small && footer}
            bottomNav={lessThan.medium && bottomNav}
            {...loadingProps}
          />
        );
      },
      delay: 200,
    });
  }

  pushToNearMePage = () => this.props.push('/near-me');

  pushToEventsPage = () => this.props.push('/events');

  pushToGroupsPage = () => this.props.push('/groups');

  pushToInboxPage = () => this.props.push('/inbox');

  scrollToTop = () => animateScroll.scrollToTop();

  render() {
    const {
      component,
      pushToNearMePage,
      pushToEventsPage,
      pushToGroupsPage,
      pushToInboxPage,
      scrollToTop,
    } = this;
    return (
      <AsyncRouteComponent
        component={authWrapper(component)}
        pushToNearMePage={pushToNearMePage}
        pushToEventsPage={pushToEventsPage}
        pushToGroupsPage={pushToGroupsPage}
        pushToInboxPage={pushToInboxPage}
        scrollToTop={scrollToTop}
        {...this.props}
      />
    );
  }
}

const mapStateToProps = createStructuredSelector({
  path: makeSelectPathName(),
  greaterThan: makeSelectGreaterThan(),
  lessThan: makeSelectLessThan(),
  isAuthenticated: makeSelectIsAuthenticated(),
});

const mapDispatchToProps = (dispatch) => ({
  push: (page) => safePush(page, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AsyncRoute);
