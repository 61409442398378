import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/es/CircularProgress';

const heigthSize = ({ footer, bottomNav }) => {
  if (footer) return 265;
  if (bottomNav) return 121;
  return 65;
};

const PageProgressBar = ({
  isLoading,
  error,
  pastDelay,
  ...props
}) => {
  if (isLoading && pastDelay) {
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          minHeight: `calc(100vh - ${heigthSize(props)}px)`,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress size={100} thickness={2} />
      </div>
    );
  }

  if (error) return <div>Error! Page failed to load</div>;
  return null;
};

PageProgressBar.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.object,
  pastDelay: PropTypes.bool.isRequired,
  footer: PropTypes.bool,
  bottomNav: PropTypes.bool,
};

export default PageProgressBar;
