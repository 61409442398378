import { fork } from 'redux-saga/effects';

import { watchSession } from '../redux/session/sagas';
import { watchUsers } from '../redux/users/sagas';
import { watchMap } from '../redux/map/sagas';
import { watchTags } from '../redux/tags/sagas';
import { watchConversation } from '../redux/conversation/sagas';

export default function* rootSaga() {
  yield [
    fork(watchSession),
    fork(watchUsers),
    fork(watchMap),
    fork(watchTags),
    fork(watchConversation)];
}
