import { call, put, takeEvery } from 'redux-saga/effects';
import { normalize, schema } from 'normalizr';
import {
  FETCH_INBOX_REQUEST,
  FETCH_INBOX_SUCCESS,
  FETCH_INBOX_FAILURE,
  FETCH_CONVERSATION_REQUEST,
  FETCH_CONVERSATION_SUCCESS,
  FETCH_CONVERSATION_FAILURE,
  POST_MESSAGE_REQUEST,
  POST_MESSAGE_SUCCESS,
  POST_MESSAGE_FAILURE,
} from './constants';
import { GET_USER_REQUEST } from '../users/constants';
import { callApi } from '../../api/api';
import { retry } from '../../utils/sagaHelpers';

/**
* Retry for users requests.
*/
export const retryConversations = retry({ delay: 200, attempt: 5 });

/**
 * Message Schema
 */
export const message = new schema.Entity('messages', {}, { idAttribute: '_id' });

/**
* Get Inbox Flow.
*/
export function* getInboxFlow(action) {
  try {
    const inboxRequestBody = {
      participant: { id: action.userId, role: 'identity' },
    };

    const params = {
      method: 'POST',
      api: 'MESSAGES',
      endpoint: '/conversations',
      body: inboxRequestBody,
      token: true,
    };

    const data = yield call(retryConversations, callApi, params);

    // fetch users
    const ids = data.map((m) => {
      return m.from === action.userId ? m.to.id : m.from;
    });
    yield ids.map((id) => put({ type: GET_USER_REQUEST, payload: id }));

    const normalized = normalize(data, [message]);
    yield put({ type: FETCH_INBOX_SUCCESS, ...normalized });
  } catch (error) {
    yield put({ type: FETCH_INBOX_FAILURE, message: error.message });
  }
}

/**
* Get Conversation Flow.
*/
export function* getConversationFlow(action) {
  try {
    const treadRequestBody = {
      participants: [{ id: action.id }, { id: action.userId }],
    };

    const params = {
      method: 'POST',
      api: 'MESSAGES',
      endpoint: '/thread',
      body: treadRequestBody,
      token: true,
    };

    const data = yield call(retryConversations, callApi, params);
    const normalized = normalize(data, [message]);
    yield put({ type: FETCH_CONVERSATION_SUCCESS, ...normalized, id: action.id });
  } catch (error) {
    yield put({ type: FETCH_CONVERSATION_FAILURE, message: error.message });
  }
}

/**
* Post Message Flow.
*/
export function* postMessageFlow(action) {
  try {
    const messageBody = {
      from: action.userId,
      to: {
        id: action.id,
      },
      content: action.message,
    };

    const params = {
      method: 'POST',
      api: 'MESSAGES',
      endpoint: '',
      body: messageBody,
      token: true,
    };

    const data = yield call(retryConversations, callApi, params);
    const normalized = normalize(data, message);
    yield put({ type: POST_MESSAGE_SUCCESS, ...normalized });
  } catch (error) {
    yield put({ type: POST_MESSAGE_FAILURE, message: error.message });
  }
}

/**
* Watch Conversation saga.
*/
export function* watchConversation() {
  yield takeEvery(FETCH_INBOX_REQUEST, getInboxFlow);
  yield takeEvery(FETCH_CONVERSATION_REQUEST, getConversationFlow);
  yield takeEvery(POST_MESSAGE_REQUEST, postMessageFlow);
}
