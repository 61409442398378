import { fromJS, Map } from 'immutable';
import {
  SIGN_UP_REQUEST,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAILURE,
  CONFIRM_SIGN_UP_REQUEST,
  CONFIRM_SIGN_UP_SUCCESS,
  CONFIRM_SIGN_UP_FAILURE,
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,
  SIGN_OUT,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  VALIDATE_TOKEN_REQUEST,
  VALIDATE_TOKEN_SUCCESS,
  VALIDATE_TOKEN_FAILURE,
  FACEBOOK_SIGN_UP_REQUEST,
  FACEBOOK_SIGN_UP_SUCCESS,
  FACEBOOK_SIGN_UP_FAILURE,
  FACEBOOK_SIGN_IN_REQUEST,
  FACEBOOK_SIGN_IN_SUCCESS,
  FACEBOOK_SIGN_IN_FAILURE,
  UPDATE_FACEBOOK_ID,
  HELP_FORM_REQUEST,
  HELP_FORM_SUCCESS,
  HELP_FORM_FAILURE,
  CONTACT_FORM_REQUEST,
  CONTACT_FORM_SUCCESS,
  CONTACT_FORM_FAILURE,
} from './constants';

/**
* Session Initial State.
*/
export const initialState = fromJS({
  isAuthenticated: false,
  authenticating: false,
  user: {},
  isEmailPasswordResetSent: false,
  isPasswordReset: false,
  loading: {
    signUp: false,
    confirmSignUp: false,
    signIn: false,
    forgotPassword: false,
    resetPassword: false,
    facebookSignUp: false,
    facebookSignIn: false,
  },
  errors: {
    signUp: false,
    confirmSignUp: false,
    signIn: false,
    forgotPassword: false,
    resetPassword: false,
    facebookSignUp: false,
    facebookSignIn: false,
  },
  helpForm: {
    isSubmitting: false,
    isSubmitted: false,
    errors: false,
  },
  contactForm: {
    isSubmitting: false,
    isSubmitted: false,
    errors: false,
  },
});

export default (state = initialState, action) => {
  switch (action.type) {
  /**
    * SIGN_UP
    */
  case SIGN_UP_REQUEST:
    return state
      .set('user', Map())
      .setIn(['loading', 'signUp'], true)
      .setIn(['errors', 'signUp'], false);
  case SIGN_UP_SUCCESS:
    return state
      .set('user', fromJS({ ...action.payload, method: 'email' }))
      .setIn(['loading', 'signUp'], false);
  case SIGN_UP_FAILURE:
    return state
      .setIn(['loading', 'signUp'], false)
      .setIn(['errors', 'signUp'], action.error);
    /**
    * CONFIRM_SIGN_UP
    */
  case CONFIRM_SIGN_UP_REQUEST:
    return state
      .setIn(['loading', 'confirmSignUp'], true)
      .setIn(['errors', 'confirmSignUp'], false);
  case CONFIRM_SIGN_UP_SUCCESS:
    return state.setIn(['loading', 'confirmSignUp'], false);
  case CONFIRM_SIGN_UP_FAILURE:
    return state
      .setIn(['loading', 'confirmSignUp'], false)
      .setIn(['errors', 'confirmSignUp'], action.error);
    /**
    * SIGN_IN
    */
  case SIGN_IN_REQUEST:
    return state
      .set('isAuthenticated', false)
      .set('user', Map())
      .setIn(['loading', 'signIn'], true)
      .setIn(['errors', 'signIn'], false);
  case SIGN_IN_SUCCESS:
    return state
      .set('isAuthenticated', true)
      .set('user', fromJS(action.payload))
      .setIn(['loading', 'signIn'], false);
  case SIGN_IN_FAILURE:
    return state
      .set('isAuthenticated', false)
      .setIn(['loading', 'signIn'], false)
      .setIn(['errors', 'signIn'], action.error);
    /**
    * SIGN_OUT
    */
  case SIGN_OUT:
    return state.set('isAuthenticated', false).set('user', Map());
    /**
    * FORGOT_PASSWORD
    */
  case FORGOT_PASSWORD_REQUEST:
    return state
      .setIn(['loading', 'forgotPassword'], true)
      .setIn(['errors', 'forgotPassword'], false);
  case FORGOT_PASSWORD_SUCCESS:
    return state
      .set('isEmailPasswordResetSent', true)
      .setIn(['loading', 'forgotPassword'], false);
  case FORGOT_PASSWORD_FAILURE:
    return state
      .set('isEmailPasswordResetSent', false)
      .setIn(['loading', 'forgotPassword'], false)
      .setIn(['errors', 'forgotPassword'], action.error);
    /**
    * RESET_PASSWORD
    */
  case RESET_PASSWORD_REQUEST:
    return state
      .setIn(['loading', 'resetPassword'], true)
      .setIn(['errors', 'resetPassword'], false);
  case RESET_PASSWORD_SUCCESS:
    return state
      .set('isPasswordReset', true)
      .setIn(['loading', 'resetPassword'], false);
  case RESET_PASSWORD_FAILURE:
    return state
      .set('isPasswordReset', false)
      .setIn(['loading', 'resetPassword'], false)
      .setIn(['errors', 'resetPassword'], action.error);
    /**
    * VALIDATE_TOKEN
    */
  case VALIDATE_TOKEN_REQUEST:
    return state
      .set('authenticating', true);
  case VALIDATE_TOKEN_SUCCESS:
    return state
      .set('isAuthenticated', true)
      .set('authenticating', false)
      .set('user', fromJS(action.payload));
  case VALIDATE_TOKEN_FAILURE:
    return state
      .set('isAuthenticated', false)
      .set('authenticating', false)
      .set('user', Map());
    /**
    * FACEBOOK_SIGN_UP
    */
  case FACEBOOK_SIGN_UP_REQUEST:
    return state
      .set('user', Map())
      .setIn(['loading', 'facebookSignUp'], true)
      .setIn(['errors', 'facebookSignUp'], false);
  case FACEBOOK_SIGN_UP_SUCCESS:
    return state
      .set('user', fromJS({ ...action.payload, method: 'facebook' }))
      .setIn(['loading', 'facebookSignUp'], false);
  case FACEBOOK_SIGN_UP_FAILURE:
    return state
      .setIn(['loading', 'facebookSignUp'], false)
      .setIn(['errors', 'facebookSignUp'], action.error);
    /**
    * FACEBOOK_SIGN_IN
    */
  case FACEBOOK_SIGN_IN_REQUEST:
    return state
      .set('isAuthenticated', false)
      .set('user', Map())
      .setIn(['loading', 'facebookSignIn'], true)
      .setIn(['errors', 'facebookSignIn'], false);
  case FACEBOOK_SIGN_IN_SUCCESS:
    return state
      .set('isAuthenticated', true)
      .set('user', fromJS(action.payload))
      .setIn(['loading', 'facebookSignIn'], false);
  case FACEBOOK_SIGN_IN_FAILURE:
    return state
      .set('isAuthenticated', false)
      .setIn(['loading', 'facebookSignIn'], false)
      .setIn(['errors', 'facebookSignIn'], action.error);
    /**
     * UPDATE_FACEBOOK_ID
     */
  case UPDATE_FACEBOOK_ID:
    return state.setIn(['user', 'facebookID'], action.payload.facebookID);

  case HELP_FORM_REQUEST:
    return state
      .setIn(['helpForm', 'isSubmitting'], true)
      .setIn(['helpForm', 'isSubmitted'], false)
      .setIn(['helpForm', 'errors'], false);
  case HELP_FORM_SUCCESS:
    return state
      .setIn(['helpForm', 'isSubmitting'], false)
      .setIn(['helpForm', 'isSubmitted'], true)
      .setIn(['helpForm', 'errors'], false);
  case HELP_FORM_FAILURE:
    return state
      .setIn(['helpForm', 'isSubmitting'], false)
      .setIn(['helpForm', 'isSubmitted'], false)
      .setIn(['helpForm', 'errors'], action.error);
  case CONTACT_FORM_REQUEST:
    return state
      .setIn(['contactForm', 'isSubmitting'], true)
      .setIn(['contactForm', 'isSubmitted'], false)
      .setIn(['contactForm', 'errors'], false);
  case CONTACT_FORM_SUCCESS:
    return state
      .setIn(['contactForm', 'isSubmitting'], false)
      .setIn(['contactForm', 'isSubmitted'], true)
      .setIn(['contactForm', 'errors'], false);
  case CONTACT_FORM_FAILURE:
    return state
      .setIn(['contactForm', 'isSubmitting'], false)
      .setIn(['contactForm', 'isSubmitted'], false)
      .setIn(['contactForm', 'errors'], action.error);
  default:
    return state;
  }
};
