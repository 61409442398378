import { call, put, takeEvery } from 'redux-saga/effects';
import { GET_MARKERS_REQUEST, GET_GEO_LOCATION_REQUEST } from './constants';
import { getMarkers, getGeoLocation } from './actions';
import getLocation from '../../utils/geoLocation';
import realMarkers from '../../static/realGroupMarkers';

/**
* Get Markers Flow.
*/
export function* getMarkersFlow(action) {
  try {
    const data = [
      ...realMarkers,
      // ...randomMarkers,
      // ...demoMarkers,
      ...action.payload,
    ];
    yield put(getMarkers.success(data));
  } catch (error) {
    yield put(getMarkers.failure(error.message));
  }
}

/**
* Get Geo Location Flow.
*/
export function* getGeoLocationFlow(action) {
  const { resolve, reject } = action.payload;
  try {
    const data = yield call(getLocation);
    const { latitude: lat, longitude: lng } = data.coords;
    const resp = { lat, lng };
    yield put(getGeoLocation.success(resp));
    resolve(resp);
  } catch (error) {
    yield put(getGeoLocation.failure(error.message));
    reject(error.message);
  }
}

/**
* Watch Map saga.
*/
export function* watchMap() {
  yield takeEvery(GET_MARKERS_REQUEST, getMarkersFlow);
  yield takeEvery(GET_GEO_LOCATION_REQUEST, getGeoLocationFlow);
}
