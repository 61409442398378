import {
  SIDE_NAV_OPEN,
  SIDE_NAV_CLOSE,
  ALERT_MODAL_OPEN,
  ALERT_MODAL_CLOSE,
  MAP_FILTER_MODAL_OPEN,
  MAP_FILTER_MODAL_CLOSE,
} from './constants';

/**
* SideNav actions.
*/
export const sideNav = {
  open: () => ({ type: SIDE_NAV_OPEN }),
  close: () => ({ type: SIDE_NAV_CLOSE }),
};

/**
* AlertModal actions.
*/
export const alertModal = {
  open: (data) => ({ type: ALERT_MODAL_OPEN, payload: data }),
  close: () => ({ type: ALERT_MODAL_CLOSE }),
};

/**
* Map Filter Modal actions.
*/
export const mapFilterModal = {
  open: () => ({ type: MAP_FILTER_MODAL_OPEN }),
  close: () => ({ type: MAP_FILTER_MODAL_CLOSE }),
};
