import React from 'react';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';

const isIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

export const SafeLink = (props) => {
  if (isIframe()) {
    return (<Link {...props} target="_blank" />);
  }
  return (<Link {...props} />);
};

export const safePush = (location, dispatch) => {
  if (isIframe()) {
    window.open(location);
  } else {
    dispatch(push(location));
  }
};
