import {
  call, put, select, takeEvery,
} from 'redux-saga/effects';
import { GET_REFERAL_TAG_REQUEST, SAVE_REFERAL_TAG_REQUEST } from './constants';
import { getReferalTag, saveReferalTag } from './actions';
import { getReferal, setReferal } from '../../utils/localStorageStore';
import { selectReferalTag } from './selectors';

/**
* Get ReferalTag Flow.
*/
export function* getReferalTagFlow() {
  try {
    let id = yield select(selectReferalTag);
    if (!id) id = yield call(getReferal);

    yield put(getReferalTag.success(id));
  } catch (error) {
    yield put(getReferalTag.failure(error.message));
  }
}

/**
* Save ReferalTag Flow.
*/
export function* saveReferalTagFlow(action) {
  try {
    yield call(setReferal, action.payload);
    yield put(saveReferalTag.success(action.payload));
  } catch (error) {
    yield put(saveReferalTag.failure(error.message));
  }
}

/**
* Watch Tags saga.
*/
export function* watchTags() {
  yield takeEvery(GET_REFERAL_TAG_REQUEST, getReferalTagFlow);
  yield takeEvery(SAVE_REFERAL_TAG_REQUEST, saveReferalTagFlow);
}
