import appConfig from '../config';

export const getYears = (startRange, stopRange) => {
  // to include the stopRange year in the constructed year increment by 1
  if (!startRange || !stopRange) return [];

  const noOfYears = (stopRange - startRange) + 1;
  return Array(noOfYears).fill()
    .map((value, index) => startRange + index);
};

export const sameDay = (d1, d2) => {
  if (!d1 || !d2) return false;
  return d1.getFullYear() === d2.getFullYear()
    && d1.getMonth() === d2.getMonth()
    && d1.getDate() === d2.getDate();
};

export const calculateAge = (birthday) => {
  const ageDifMs = Date.now() - (new Date(birthday)).getTime();
  const ageDate = new Date(ageDifMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

export const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December',
];

export const getYearsForDropDown = () => {
  const now = (new Date()).getFullYear();
  return getYears(now - appConfig.BIRTHDAY_START_YR_LIMIT, now - appConfig.BIRTHDAY_END_YR_LIMIT);
};
