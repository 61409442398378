import {
  GET_REFERAL_TAG_REQUEST,
  GET_REFERAL_TAG_SUCCESS,
  GET_REFERAL_TAG_FAILURE,
  SAVE_REFERAL_TAG_REQUEST,
  SAVE_REFERAL_TAG_SUCCESS,
  SAVE_REFERAL_TAG_FAILURE,
} from './constants';

/**
* Get referalTag actions.
*/
export const getReferalTag = {
  request: () => ({ type: GET_REFERAL_TAG_REQUEST }),
  success: (id) => ({ type: GET_REFERAL_TAG_SUCCESS, payload: id }),
  failure: (error) => ({ type: GET_REFERAL_TAG_FAILURE, error }),
};

/**
* Save referalTag actions.
*/
export const saveReferalTag = {
  request: (id) => ({ type: SAVE_REFERAL_TAG_REQUEST, payload: id }),
  success: (id) => ({ type: SAVE_REFERAL_TAG_SUCCESS, payload: id }),
  failure: (error) => ({ type: SAVE_REFERAL_TAG_FAILURE, error }),
};
