// ------------------------------------------------------------------------
// How to use Routes
//
// path: '/' it is a url path
// route: 'Home' - path src/routes/Home
// authenticated: true - if an user is not authenticated then redirect to
// sign in page
// visibleOnlyGuest: true - if an user is authenticated then redirect to
// home page
// footer: true - show a footer on this route
// bottomNav: true - show a bottom navigation on this route
// ------------------------------------------------------------------------
const routes = [
  {
    path: '/',
    route: 'Home',
    exact: true,
    footer: true,
    bottomNav: true,
  },
  {
    path: '/about',
    route: 'About',
    footer: true,
  },
  {
    path: '/store',
    route: 'Store',
    footer: true,
  },
  {
    path: '/contact',
    route: 'Contact',
    visibleOnlyGuest: true,
    footer: true,
  },
  {
    path: '/help',
    route: 'Help',
    authenticated: true,
    footer: true,
  },
  {
    path: '/terms',
    route: 'Terms',
    footer: true,
  },
  {
    path: '/signup',
    route: 'SignUp',
    exact: true,
    visibleOnlyGuest: true,
    footer: true,
  },
  {
    path: '/signup/2',
    route: 'SignUpSecondStep',
    exact: true,
    visibleOnlyGuest: true,
    footer: true,
  },
  {
    path: '/signup/confirmation/:token',
    route: 'SignUpConfirmation',
    visibleOnlyGuest: true,
    footer: true,
  },
  {
    path: '/signin',
    route: 'SignIn',
    visibleOnlyGuest: true,
    footer: true,
  },
  {
    path: '/forgot-password',
    route: 'ForgotPassword',
    visibleOnlyGuest: true,
    footer: true,
  },
  {
    path: '/reset-password/:token',
    route: 'ResetPassword',
    footer: true,
  },
  {
    path: '/ref/:id',
    route: 'Referal',
  },
  {
    path: ['/near-me', '/near-me/category/:cat'],
    route: 'NearMe',
    authenticated: false,
    bottomNav: true,
  },
  {
    path: '/events',
    route: 'Events',
    authenticated: true,
    bottomNav: true,
    footer: true,
  },
  {
    path: '/groups',
    route: 'Groups',
    authenticated: true,
    bottomNav: true,
  },
  {
    path: '/inbox',
    route: 'Inbox',
    exact: true,
    authenticated: true,
    bottomNav: true,
    footer: true,
  },
  {
    path: '/profile',
    route: 'Profile',
    authenticated: true,
    footer: true,
  },
  {
    path: '/inbox/:id',
    route: 'Conversation',
    authenticated: true,
  },
  {
    path: '/alerts',
    route: 'Alerts',
    authenticated: true,
    footer: true,
  },
  {
    path: '/users/:id',
    route: 'Users',
    authenticated: true,
    footer: true,
  },
];

export default routes;
