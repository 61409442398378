import {
  CALCULATE_MAP_STATE,
  SET_MARKERS_FILTER,
  GET_MARKERS_REQUEST,
  GET_MARKERS_SUCCESS,
  GET_MARKERS_FAILURE,
  GET_GEO_LOCATION_REQUEST,
  GET_GEO_LOCATION_SUCCESS,
  GET_GEO_LOCATION_FAILURE,
  SET_MAP_CENTER_STATE,
  SET_MAP_ZOOM_STATE,
} from './constants';

/**
* Set Map Data action.
*/
export const setMapData = (data) => {
  return ({ type: CALCULATE_MAP_STATE, payload: data });
};

/**
 * Set Map Center action.
 */
export const setMapCenter = (data) => {
  return ({ type: SET_MAP_CENTER_STATE, payload: data });
};

/**
 * Set Map Zoom action.
 */
export const setMapZoom = (data) => {
  return ({ type: SET_MAP_ZOOM_STATE, payload: data });
};

/**
* Set Markers Filter action.
*/
export const setMarkersFilter = (data) => {
  return ({ type: SET_MARKERS_FILTER, payload: data });
};

/**
* Get Markers actions.
*/
export const getMarkers = {
  request: (data) => ({ type: GET_MARKERS_REQUEST, payload: data }),
  success: (data) => ({ type: GET_MARKERS_SUCCESS, payload: data }),
  failure: (error) => ({ type: GET_MARKERS_FAILURE, error }),
};

/**
* Get GeoLocation actions.
*/
export const getGeoLocation = {
  request: (data) => ({ type: GET_GEO_LOCATION_REQUEST, payload: data }),
  success: (data) => ({ type: GET_GEO_LOCATION_SUCCESS, payload: data }),
  failure: (error) => ({ type: GET_GEO_LOCATION_FAILURE, error }),
};
