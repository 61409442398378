export const CALCULATE_MAP_STATE = 'CALCULATE_MAP_STATE';

export const SET_MARKERS_FILTER = 'SET_MARKERS_FILTER';
export const SET_MAP_CENTER_STATE = 'SET_MAP_CENTER_STATE';
export const SET_MAP_ZOOM_STATE = 'SET_MAP_ZOOM_STATE';

export const GET_MARKERS_REQUEST = 'GET_MARKERS_REQUEST';
export const GET_MARKERS_SUCCESS = 'GET_MARKERS_SUCCESS';
export const GET_MARKERS_FAILURE = 'GET_MARKERS_FAILURE';

export const GET_GEO_LOCATION_REQUEST = 'GET_GEO_LOCATION_REQUEST';
export const GET_GEO_LOCATION_SUCCESS = 'GET_GEO_LOCATION_SUCCESS';
export const GET_GEO_LOCATION_FAILURE = 'GET_GEO_LOCATION_FAILURE';
