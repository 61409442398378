import { fromJS } from 'immutable';
import {
  SIDE_NAV_OPEN,
  SIDE_NAV_CLOSE,
  ALERT_MODAL_OPEN,
  ALERT_MODAL_CLOSE,
  MAP_FILTER_MODAL_OPEN,
  MAP_FILTER_MODAL_CLOSE,
} from './constants';

/**
* App Initial State.
*/
export const initialState = fromJS({
  sideNavIsOpen: false,
  alertModalIsOpen: false,
  mapFilterModalIsOpen: false,
  data: {
    alertModal: {
      title: 'ERROR',
      description: 'ERROR',
    },
  },
});

export default (state = initialState, action) => {
  switch (action.type) {
  /**
    * SIDE_NAV
    */
  case SIDE_NAV_OPEN:
    return state
      .set('sideNavIsOpen', true);
  case SIDE_NAV_CLOSE:
    return state
      .set('sideNavIsOpen', false);
    /**
    * ALERT_MODAL
    */
  case ALERT_MODAL_OPEN:
    return state
      .set('alertModalIsOpen', true)
      .setIn(['data', 'alertModal', 'title'], action.payload.title)
      .setIn(
        ['data', 'alertModal', 'description'],
        action.payload.description,
      );
  case ALERT_MODAL_CLOSE:
    return state
      .set('alertModalIsOpen', false);
    /**
    * MAP_FILTER_MODAL
    */
  case MAP_FILTER_MODAL_OPEN:
    return state
      .set('mapFilterModalIsOpen', true);
  case MAP_FILTER_MODAL_CLOSE:
    return state
      .set('mapFilterModalIsOpen', false);
  default:
    return state;
  }
};
