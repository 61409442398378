import { createSelector } from 'reselect';

/**
* Router selector.
*/
export const selectRouter = (state) => state.get('router');

/**
* Bbrowser selector.
*/
export const selectBrowser = (state) => state.get('browser');

/**
* App selector.
*/
export const selectApp = (state) => state.get('app');

/**
* Data selector.
*/
export const selectData = (state) => state.getIn(['app', 'data']);

/**
* PathName selector.
*/
export const makeSelectPathName = () => createSelector(
  selectRouter,
  (routerState) => routerState.getIn(['location', 'pathname']),
);

/**
* LessThan selector.
*/
export const makeSelectLessThan = () => createSelector(
  selectBrowser,
  (browserState) => browserState.lessThan,
);

/**
* GreaterThan selector.
*/
export const makeSelectGreaterThan = () => createSelector(
  selectBrowser,
  (browserState) => browserState.greaterThan,
);

/**
* Side navigation status selector.
*/
export const makeSelectSideNavIsOpen = () => createSelector(
  selectApp,
  (appState) => appState.get('sideNavIsOpen'),
);

/**
* Alert modal window status selector.
*/
export const makeSelectAlertModalIsOpen = () => createSelector(
  selectApp,
  (appState) => appState.get('alertModalIsOpen'),
);

/**
* Map filter modal window status selector.
*/
export const makeSelectMapFilterModalIsOpen = () => createSelector(
  selectApp,
  (appState) => appState.get('mapFilterModalIsOpen'),
);

/**
* Alert data selector.
*/
export const makeSelectAlertModalData = () => createSelector(
  selectData,
  (dataState) => dataState.get('alertModal'),
);
