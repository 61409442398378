import { fromJS } from 'immutable';
import {
  FETCH_INBOX_REQUEST,
  FETCH_INBOX_SUCCESS,
  FETCH_INBOX_FAILURE,
  FETCH_CONVERSATION_REQUEST,
  FETCH_CONVERSATION_SUCCESS,
  FETCH_CONVERSATION_FAILURE,
} from './constants';

export const initialState = fromJS({
  inbox: {},
});

export default (state = initialState, action) => {
  switch (action.type) {
  /**
    * GET_INBOX
    */
  case FETCH_INBOX_REQUEST:
    return state.setIn(['inbox', 'loading'], true);

  case FETCH_INBOX_SUCCESS:
    return state
      .setIn(['inbox', 'loading'], false)
      .setIn(['inbox', 'fetched'], true);

  case FETCH_INBOX_FAILURE:
    return state
      .setIn(['inbox', 'loading'], false)
      .setIn(['inbox', 'error'], action.message);

    /**
    * GET_CONVERSATION
    */
  case FETCH_CONVERSATION_REQUEST:
    return state.setIn([action.id, 'loading'], true);

  case FETCH_CONVERSATION_SUCCESS:
    return state
      .setIn([action.id, 'loading'], false)
      .setIn([action.id, 'fetched'], true);

  case FETCH_CONVERSATION_FAILURE:
    return state
      .setIn([action.id, 'loading'], false)
      .setIn([action.id, 'error'], action.message);

  default:
    return state;
  }
};
