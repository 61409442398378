import grey from '@material-ui/core/es/colors/grey';
import common from '@material-ui/core/es/colors/common';
import { fade } from '@material-ui/core/es/styles/colorManipulator';
import createMuiTheme from '@material-ui/core/es/styles/createMuiTheme';

const grey900 = grey['900'];
const fullWhite = common.white;
const red = 'rgb(143, 25, 34)';
const grey100 = 'rgb(33,33,33)';

const Theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: [
      'Roboto, sans-serif',
    ],
  },
  palette: {
    type: 'dark',
    primary: {
      main: red,
      contrastText: fullWhite,
    },
    secondary: {
      main: grey100,
      contrastText: fullWhite,
    },
    error: {
      main: red,
      dark: red,
      contrastText: fullWhite,
    },
    action: {
      hoverOpacity: 0.21,
    },
    background: {
      paper: grey100,
    },
    text: {
      primary: fullWhite,
      secondary: fade(fullWhite, 0.5),
    },
    canvasColor: grey900,
    textColor: fade(fullWhite, 0.8),
    borderColor: fade(fullWhite, 0.2),
    disabledColor: fade(fullWhite, 0.2),
    pickerHeaderColor: fade(fullWhite, 0.12),
    clockCircleColor: fade(fullWhite, 0.12),
  },
  overrides: {
    MuiButton: {
      root: {
        height: 45,
        borderRadius: 2,
      },
      raisedPrimary: {
        '&:hover': {
          backgroundColor: fade(red, 0.55),
        },
      },
      containedPrimary: {
        '&:hover': {
          backgroundColor: fade(red, 0.55),
        },
      },
    },
    MuiCheckbox: {
      root: {
        color: fullWhite,
      },
    },
    MuiPaper: {
      root: {
        color: fullWhite,
      },
    },
    MuiFormControl: {
      root: {
        display: 'flex',
      },
    },
    MuiFormLabel: {
      '&$focused': {
        color: `${fade(fullWhite, 0.2)} !important`,
      },
      error: {
        color: `${red} !important`,
      },
    },
    MuiInput: {
      input: {
        color: fullWhite,
        borderBottomColor: fade(fullWhite, 0.2),
      },
      underline: {
        '&:before': {
          borderBottomColor: fade(fullWhite, 0.2),
        },
      },
    },
    MuiRadio: {
      root: {
        color: fullWhite,
      },
    },
  },
  raisedButton: {
    textColor: red,
  },
  textField: {
    errorColor: red,
    focusColor: fade(fullWhite, 0.2),
  },
});

export default Theme;
